// eslint-disable-next-line import/no-anonymous-default-export
export default function() {
  return [
    {
      title: "Panel de control",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
    },
    {
      title: "Clientes",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/blog-posts",
    },
    {
      title: "Nuevo cliente",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-new-post",
    },
    {
      title: "Trabajadores",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/trabajadores",
    },
    {
      title: "Nuevo trabajador",
      htmlBefore: '<i class="material-icons">person_add</i>',
      to: "/nuevo-trabajador",
    },
    // {
    //   title: "Facturas",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    // {
    //   title: "Añadir factura",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },
    {
      title: "Perfil",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    },
    {
      title: "Notificaciones",
      htmlBefore: '<i class="material-icons">error</i>',
      to: "/errors",
    },
    {
      title: 'Empresas',
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: '/empresas-trabajo',
    },
    {
      title: 'Tareas',
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: '/tareas',
    },
  ];
}
