import React, { useState, useEffect } from "react";
import { Container, Row } from "shards-react";
import { ToastContainer, Slide } from 'react-toastify';
import PageTitle from "../components/common/PageTitle";
import axios from 'axios';
import OtsCliente from "../components/ots/OtsCliente";
import { cookies } from "../helpers/createCookies";

const OTsCliente = ({props}) => {
    const [nombreClientePrincipal, setNombreClientePrincipal] = useState('');
    useEffect(() => {
        const source = axios.CancelToken.source();
        axios.get(`/getNombreClientePrincipal?token=${cookies.get('token')}&id=${props.match.params.id}`, { cancelToken: source.token, }).then(({ data }) => {
            console.log(data)
            setNombreClientePrincipal(data);
        })
        .catch((err) => {
            console.log(err);
        });
        return () => {
            source.cancel();
        };
    });
    return (
        <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title={nombreClientePrincipal} subtitle="Órdenes de trabajo" className="text-sm-left" />
        </Row>
        <OtsCliente props={props} id={props.match.params.id} nombre={nombreClientePrincipal} tipo_ots={0} />
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            transition={Slide}
        />
    </Container>
    )
}

export default OTsCliente;
