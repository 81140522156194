const d = new Date();
const yyear = d.getFullYear();
let month = (d.getMonth()+1).toString();
if(month.length < 2) month = `0${month}`;
let day = d.getDate().toString();
if(day.length < 2) day = `0${day}`;
const info = {
    dias: new Date(yyear, month, 0).getDate(),
    dia: day,
    mes: month,
    year: yyear,
}
const { dias, dia, mes, year } = info;
export { dias, dia, mes, year };