import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import './assets/calendar-data.css';
import axios from "axios";
import { cookies } from "./helpers/createCookies";

// axios.defaults.baseURL = 'http://localhost:3030';
axios.defaults.baseURL = 'https://pruebadominio.website/';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [ok, setOk] = useState(false);
  useEffect(() => {
    const source = axios.CancelToken.source();
    if(cookies.get('token')) {
      axios.get(`/getTipoUsuario?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
        cookies.remove('tipo_usuario', { path: '/' });
        cookies.set('tipo_usuario', data[0].tipo_trabajador);
        setOk(true);
      }).catch((err) => {
          console.log(err);
      });
    } else {
      setOk(true);
    }
    return () => {
        source.cancel();
    };
  }, [])
  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <div>
        { ok ? 
          routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker(props => {
                  return (
                    <route.layout {...props} noNavbar={route.noNavbar} noFooter={route.noFooter} noMainSidebar={route.noMainSidebar} login={route.login}>
                      <route.component {...props} />
                    </route.layout>
                  );
                })}
              />
            )
          })
          :
          <span>Cargando datos...</span>
        }
      </div>
    </Router>
  );
};
