import { createSlice } from '@reduxjs/toolkit'

export const tareaSeleccionadaReducer = createSlice({
  name: 'tareaSeleccionada',
  initialState: {
    estado: null
  },
  reducers: {
    updateEstado: (state, action) => {
        console.log(action.payload);
        state.estado = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateEstado } = tareaSeleccionadaReducer.actions;

export const estado = (state) => state.tareaSeleccionada.estado;

export default tareaSeleccionadaReducer.reducer;