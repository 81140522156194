import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Modal, ModalFooter, Button, InputGroupAddon, InputGroupText, ModalHeader, ModalBody, Form, FormGroup, InputGroup, FormInput, FormSelect } from "shards-react";
import axios from "axios";
import styled from "styled-components";
import { showToast } from "../../helpers/toast";
import { useSelector, useDispatch } from "react-redux";
import { equipo, trabajador, idOt, fecha, hora, reset, updateFecha, updateHora, setDataOt } from "../../reducers/otsReducer";
import ModalData from "./ModalData";
import { cookies } from "../../helpers/createCookies";

/*
    tipo_ots:
        0 -> OTs no asignadas
        1 -> OTs pendientes de validar
        2 -> OTs cerradas
*/

const OtsCliente = ({ props, id, nombre, tipo_ots }) => {
    const [ots, setOts] = useState([]);
    const [otData, setOtData] = useState([]);
    const [modalNuevaOt, setModalNuevaOt] = useState(false);
    const [nuevaOt, setNuevaOt] = useState({
        token: cookies.get('token'),
        cliente: nombre,
        id: '',
        mensaje: '',
        asunto: '',
    });
    const dispatch = useDispatch();
    useEffect(() => {
        const source = axios.CancelToken.source();
        console.log(tipo_ots)
        getOts(source);
        return () => {
            source.cancel();
        };
    }, [id]);
    const getOts = (source) => {
        if(tipo_ots === 0) {
            axios.get(`/getOtsCliente?token=${cookies.get('token')}&id=${id}`, { cancelToken: source.token, }).then(({ data }) => {
                setOts(data);
            })
            .catch((err) => {
                console.log(err);
            });
            return;
        }
        if(tipo_ots === 1) {
            axios.get(`/getOtsPendientesCliente?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
                setOts(data);
                console.log(data)

            })
            .catch((err) => {
                console.log(err);
            });
            return;
        }
        if(tipo_ots === 2) {
            axios.get(`/getOtsCerradasCliente?token=${cookies.get('token')}&id=${id}`, { cancelToken: source.token, }).then(({ data }) => {
                setOts(data);
            })
            .catch((err) => {
                console.log(err);
            });
            return;
        }
    }
    const verOt = (e, data) => {
        console.log(data)
        data = { ...data, id_cliente: tipo_ots === 0 ? id : data.cliente };
        setOtData(data);
        dispatch(setDataOt(data));
        console.log(data)
        props.history.push(`/orden-de-trabajo/${data.id}`);
    }
    const onClickNuevaOt = (e) => {
        e.preventDefault();
        setNuevaOt({
            ...nuevaOt,
            cliente: nombre,
        });
        setModalNuevaOt(true);
    }
    const closeModalNuevaOt = (e) => {
        e.preventDefault();
        setModalNuevaOt(false);
    }
    const saveDataNuevaOt = (e) => {
        if(!nuevaOt.id.trim() || !nuevaOt.asunto.trim() || !nuevaOt.mensaje.trim()) {
            showToast('warn', 'Ningún campo puede estar vacío.');
            return;
        }
        axios.post('/crearNuevaOT', nuevaOt).then(({ data }) => {
            if(data.existeOt) {
                showToast('err', 'Ese ID de OT ya existe.');
                return;
            }            
            const source = axios.CancelToken.source();
            getOts(source);
            showToast('ok', 'OT creada correctamente.');
            setModalNuevaOt(false);
        }).catch((err) => {
            console.log(err);
            showToast('err', 'Error general, si el problema persiste, contacta con el administrador.');
        })
    }
    const handleOnChangeNuevaOt = (e) => {
        setNuevaOt({
            ...nuevaOt,
            [e.target.name]: e.target.value,
        });
    }
    return (
        <>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row>
                                <Col md='10' sm='9'>
                                    <h6 className="m-0">Órdenes de trabajo</h6>
                                </Col>
                                <Col>
                                    <Button theme='dark' onClick={onClickNuevaOt}><span className="text-white-50"><i className="fas fa-plus"></i></span> Nueva OT</Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0">
                            <table className="table mb-0">
                                <thead className="bg-light">
                                    <tr>
                                        <th scope="col" className="border-0">
                                            #
                                        </th>
                                        <th scope="col" className="border-0">
                                            Id
                                        </th>
                                        <th scope="col" className="border-0">
                                            OT
                                        </th>
                                        <th scope="col" className="border-0">
                                            Cliente
                                        </th>
                                        <th scope="col" className="border-0">
                                            Asunto
                                        </th>
                                        <th scope="col" className="border-0">
                                            Trabajador a cargo
                                        </th>
                                        <th scope="col" className="border-0">
                                            Equipo a cargo
                                        </th>
                                        <th scope="col" className="border-0">
                                            Estado
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        ots.map(({ id, nombre, asunto, mensaje, cliente, trabajador_asociado, equipo_asociado, finalizado, nombre_trabajador, nombre_equipo, nombre_cliente, cif, telefono, cifEmpresa, direccionEmpresa, provinciaEmpresa, comentario_administracion, comentario_tecnico, year_ot, plantilla_asociada }, index) => {
                                            const data = { id, nombre, asunto, mensaje, cliente, trabajador_asociado, equipo_asociado, finalizado, nombre_trabajador, nombre_equipo, nombre_cliente, cif, telefono, cifEmpresa, direccionEmpresa, provinciaEmpresa, comentario_administracion, comentario_tecnico, year_ot, plantilla_asociada };
                                            return (
                                                <Tr key={index} id={id} style={{ cursor: 'pointer' }} onClick={(e) => verOt(e, data)}>
                                                    <td>{index+1}</td>
                                                    <td>{year_ot}-{id}</td>
                                                    <td>{nombre.slice(2)}</td>
                                                    <td>{nombre_cliente}</td>
                                                    <td>{asunto}</td>
                                                    <td>{nombre_trabajador ? nombre_trabajador : 'Ninguno'}</td>
                                                    <td>{nombre_equipo ? nombre_equipo : 'Ninguno'}</td>
                                                    <td>
                                                        {
                                                            finalizado === 1 ? 'Pendiente' 
                                                            : finalizado === 2 ? 'En curso'
                                                            : finalizado === 3 ? 'Pendiente de validar'
                                                            :  'Finalizada'
                                                        }
                                                    </td>
                                                </Tr>
                                            )  
                                        })
                                    }
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
            <Modal open={modalNuevaOt} toggle={closeModalNuevaOt} size='lg'>
                <ModalHeader>Nueva OT | {nombre}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <FormInput name='id' placeholder='Id OT' onChange={handleOnChangeNuevaOt} />
                        </FormGroup>
                        <FormGroup>
                            <FormInput name='asunto' placeholder='Asunto' onChange={handleOnChangeNuevaOt} />
                        </FormGroup>
                        <FormGroup>
                            <FormInput name='mensaje' placeholder='Mensaje' onChange={handleOnChangeNuevaOt} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline theme='danger' onClick={closeModalNuevaOt}>Cancelar</Button>
                    <Button outline onClick={saveDataNuevaOt}>Guardar</Button>
                </ModalFooter>
            </Modal>
            </Row>
        </>
    );
};

const Icon = styled.i.attrs((props) => ({
    className: props.className || 'material-icons',
}))`
    display: inline;
    margin-right: 1vw;
    cursor: pointer;
    transition: all .2s ease-in-out;
    color: white;
    ${({ name }) => name === 'visibility' &&`
        border: 1px solid transparent;
        border-radius: 5px;
        padding: .7em;
        background: green;
        &:hover {
            border: 1px solid green;
            background: transparent;
            color: black;
        }
    `}
    ${({ name }) => name === 'delete' &&`
        border: 1px solid transparent;
        border-radius: 5px;
        padding: .7em;
        background: red;
        &:hover {
            border: 1px solid red;
            background: transparent;
            color: black;
        }
    `}
`;

const Tr = styled.tr`
    cursor: 'pointer';
    transition: all .1s linear;
    &:hover {
        background-color: rgb(236, 236, 236);;
    }
`;

export default OtsCliente;
