import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Col, Row } from 'shards-react';
import { cookies } from '../../helpers/createCookies';

const DAY_HOURS = [...Array(24).keys()];

const InfoDia = ({ data }) => {
    const [tareas, setTareas] = useState(data);
    const [dataOk, setDataOk] = useState(false);
    
    return (
        <>
            <Col>
                <Row>
                    <Col>
                        <b>OT</b>
                    </Col>
                    <Col>
                        <b>Asunto</b>
                    </Col>
                    <Col>
                        <b>Fecha</b>
                    </Col>
                    <Col>
                        <b>Hora entrada</b>
                    </Col>
                    <Col>
                        <b>Hora salida</b>
                    </Col>
                    <Col>
                        <b>Estado</b>
                    </Col>
                </Row>
                <hr />
                {
                    typeof data === 'object' ?
                    data.map(({ id_ot, asunto, fecha, hora, salida, finalizado }, index) => {
                        return (
                            <Row>
                                <Col>
                                    {id_ot}
                                </Col>
                                <Col>
                                    {asunto}
                                </Col>
                                <Col>
                                    {fecha}
                                </Col>
                                <Col>
                                    {hora}
                                </Col>
                                <Col>
                                    {salida}
                                </Col>
                                <Col>
                                    {finalizado === 1 ? 'Pendiente' : 'Finalizada'}
                                </Col>
                            </Row>
                            )
                        })
                        :
                        <p>{data}</p>
                    }
            </Col>
        </>
    )
}

export default InfoDia;
