import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { dias, dia, mes } from '../../helpers/getInfoMonth';
import { showToast } from '../../helpers/toast';
import { cookies } from '../../helpers/createCookies';
import { useDispatch } from 'react-redux';
import { updateTotalPuntos } from '../../reducers/perfilUsuarioReducer';

const ResumenDias = () => {
    const diasTotales = [...Array(dias).keys()];
    const [datosMes, setDatosMes] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        const source = axios.CancelToken.source();
        axios.get(`/getPuntosTrabajador?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
            setDatosMes(data);
            dispatch(updateTotalPuntos(data.reduce((total, o) => o.puntos + total, 0)));
        })
        .catch((err) => {
            console.log(err);
        });
        return () => {
            source.cancel();
        };
    }, [])
    const [diaSelected, setDiaSelected] = useState(dia);
    const selectDay = (e) => {
        let diaClicked = parseInt(e.target.id) + 1;
        if(diaClicked > dia) return;
        if(diaClicked.toString().length < 2) diaClicked = `0${diaClicked}`;
        setDiaSelected(diaClicked);
    }
    const getPuntos = () => {
        console.log(diaSelected)
        const puntos = datosMes.filter(o => parseInt(o.dia) === parseInt(diaSelected)).map(o => o.puntos);
        return puntos.length > 0 ? puntos : 'Sin información';
    }
    return (
        <div>
            {
                diasTotales.map((item, index, array) => {
                    let tipo;
                    if(dia < item + 1) tipo = '4';
                    else {
                        const trabajadorDiaData = datosMes.filter(o => parseInt(o.dia) === item + 1);
                        if(trabajadorDiaData.length > 0) {
                            if(trabajadorDiaData[0].puntos > 15) tipo = '1';
                            else if(trabajadorDiaData[0].puntos === 15) tipo = '2';
                            else if(trabajadorDiaData[0].puntos < 15) tipo = '3';
                        }
                    }
                    return (
                        <>
                            <DayContainer tipo={tipo} key={index} id={index} onClick={selectDay}>
                                <Day id={index}>{ item + 1 }</Day>
                            </DayContainer>
                            {(item + 1) % 7 === 0 && <br />} 
                        </>
                    )
                })
            }
            <br /><br />
            <h5>Puntos {diaSelected}/{mes}: { getPuntos() }</h5>
        </div>
    )
}

const DayContainer = styled.div`
    display: inline-block;
    height: 5vh;
    width: 2vw;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    margin: 1px;
    cursor: pointer;
    ${({ tipo }) => tipo === '1' &&`
        background-color: green;
    `}
    ${({ tipo }) => tipo === '2' &&`
        background-color: blue;
    `}
    ${({ tipo }) => tipo === '3' &&`
        background-color: red;
    `}
    ${({ tipo }) => tipo === '4' &&`
        background-color: gray;
        cursor: default;
    `}
`;
const Day = styled.span`
    display: inline-block;
    background-color: white;
    width: 100%;
    border-radius: 5px 5px 0 0;
`;
export default ResumenDias;
