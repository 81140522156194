import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import { Container, Row, Col } from "shards-react";
import TrabajadoresCards from '../components/trabajadores/TrabajadoresCards';
import PageTitle from "./../components/common/PageTitle";

const Trabajadores = (props) => {
    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle title="Trabajadores" subtitle="Todos los" className="text-sm-left mb-3" />
            </Row>
            <TrabajadoresCards props={props} />
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                transition={Slide}
            />
        </Container>
    )
}

export default Trabajadores;
