import React, { useState, useEffect, Children } from "react";
import axios from "axios";
import styled from "styled-components";
import { cookies } from "../../helpers/createCookies";
import { showToast } from "../../helpers/toast";

const Documentos = () => {
  const [documentos, setDocumentos] = useState([]);
  const [trabajadores, setTrabajadores] = useState([]);
  const [trabajadorSeleccionado, setTrabajadorSeleccionado] = useState(0);
  const [tipoArchivoSeleccionado, setTipoArchivoSeleccionado] = useState(0);
  
  useEffect(() => {
    const source = axios.CancelToken.source();
    getDocumentos(source);
    getTrabajadores(source);
    return () => {
      source.cancel();
    };
  }, []);
  const getDocumentos = (source, trabajador = 0, tipo_archivo = 0) => {
    console.log(tipoArchivoSeleccionado)
    const url =
      cookies.get("tipo_usuario") === "1"
        ? `/getDocumentos?token=${cookies.get("token")}&non_admin=1&trabajador=${trabajador}&tipo_archivo=${tipo_archivo}`
        : `/getDocumentos?token=${cookies.get("token")}&non_admin=0`;
    axios
      .get(url, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        setDocumentos(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTrabajadores = (source) => {
    axios.get(`/getTrabajadores?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
        setTrabajadores(data.data);
    }).catch((err) => {
        console.log(err);
    });
  }
  const downloadDocumento = (e, id) => {
    e.preventDefault();
    axios
      .post("/downloadDocumento", { token: cookies.get("token"), id })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data.file]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteDocumento = (e, id) => {
    e.preventDefault();
    axios
      .post("/borrarDocumento", { token: cookies.get("token"), id })
      .then(({ data }) => {
        showToast("ok", "Documento borrado correctamente");
        getDocumentos(axios.CancelToken.source());
      })
      .catch((err) => {
        console.log(err);
        showToast("err", "Error al borrar el documento");
      });
  };

  const changeTrabajadorSeleccionado = (e) => {
    setTrabajadorSeleccionado(e.target.value);
    getDocumentos(axios.CancelToken.source(), e.target.value, tipoArchivoSeleccionado);
  }

  const changeTipoArchivoSeleccionado = (e) => {
    setTipoArchivoSeleccionado(e.target.value);
    getDocumentos(axios.CancelToken.source(), trabajadorSeleccionado, e.target.value);
  }

  return (
    <Container>
      <FiltersContainer>
        <SelectStyle value={trabajadorSeleccionado} onChange={changeTrabajadorSeleccionado}>
          <option value='0'>Todos los trabajadores</option>
          {
            trabajadores.map((item, index) => 
              <option key={index} value={item.id}>{item.nombre}</option>
            )
          }
        </SelectStyle>
        <SelectStyle value={tipoArchivoSeleccionado} onChange={changeTipoArchivoSeleccionado}>
          <option value='0'>Todos los archivos</option>
          <option value='1'>PDF</option>
          <option value='2'>Imagen</option>
        </SelectStyle>
      </FiltersContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Nombre</Th>
            {cookies.get("tipo_usuario") === "1" && <Th>Empleado</Th>}
            <Th>Tipo archivo</Th>
            <Th>Acciones</Th>
          </Tr>
        </Thead>
        <Tbody>
          {documentos && documentos.length ? (
            documentos.map((documento, index) => {
              let archivo = documento.nombre;
              let last_dot_index = archivo.lastIndexOf('.');
              return (
                <Tr key={index}>
                  <Td>{archivo.slice(0, last_dot_index)}</Td>
                  {cookies.get("tipo_usuario") === "1" && (
                    <Td>{documento.nombre_trabajador}</Td>
                  )}
                  <Td>{archivo.slice(last_dot_index + 1).toUpperCase()}</Td>
                  <Td>
                    <Btn onClick={(e) => downloadDocumento(e, documento.id)}>
                      Descargar
                    </Btn>
                    <Btn onClick={(e) => deleteDocumento(e, documento.id)}>
                      Eliminar
                    </Btn>
                  </Td>
                </Tr>
              );
            })
          ) : (
            <NoDocumentos>No hay ningún documento creado</NoDocumentos>
          )}
        </Tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
  margin-bottom: 1em;
  color: black;
`;

const Thead = styled.thead`
  background-color: white;
`;

const Tbody = styled.tbody`
  background-color: #fff;
  tr:nth-child(odd) {
    background-color: #c2dcff;
  }
`;

const Tr = styled.tr`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

const Th = styled.th`
  border: none;
  padding: 8px;
`;

const Td = styled.td`
  padding: 0.8em;
`;

const Btn = styled.button`
  background-color: #00a8ff;
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 0.5em;
  &:hover {
    background-color: #0097e6;
  }
`;

const NoDocumentos = styled.div`
  text-align: center;
  padding: 1em;
`;

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  border-radius: 5px;
  padding: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
`;

const SelectStyle = styled.select`
  background-color: white;
  border-radius: 5px;
`;

export default Documentos;
