import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import axios from "axios";
import styled from "styled-components";
import { showToast } from "../../helpers/toast";
import { cookies } from "../../helpers/createCookies";
import { useDispatch } from "react-redux";
import { updateId } from "../../reducers/empresaTrabajoReducer";
 
const EmpresasTable = ({ props }) => {
    const [empresasData, setEmpresasData] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        const source = axios.CancelToken.source();
        axios.get(`/getEmpresasTrabajo?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
            setEmpresasData(data);
        })
        .catch((err) => {
            console.log(err);
        });
        return () => {
            source.cancel();
        };
    }, []);
    const verClientesEmpresa = (e) => {
        console.log(e.target.parentNode.parentNode.getAttribute('id'))
        dispatch(updateId(e.target.parentNode.parentNode.getAttribute('id')));
        props.history.push(`/ordenes-de-trabajo`);
    }
    return (
        <Row>
        <Col>
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">Empresas Princiales</h6>
                </CardHeader>
                <CardBody className="p-0">
                    <table className="table mb-0">
                        <thead className="bg-light">
                            <tr>
                                <th scope="col" className="border-0">
                                    #
                                </th>
                                <th scope="col" className="border-0">
                                    Nombre
                                </th>
                                <th scope="col" className="border-0">
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                empresasData.map(({id, nombre}, index) => (
                                        <tr key={index} id={id}>
                                            <td>{index+1}</td>
                                            <td>{nombre}</td>
                                            <td>
                                                <Icon className='material-icons' name='visibility' onClick={verClientesEmpresa}>visibility</Icon>
                                            </td>
                                        </tr>
                                    )  
                                )
                            }
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        </Col>
    </Row>
    )
}

const Icon = styled.i.attrs((props) => ({
    className: props.className || 'material-icons',
}))`
    display: inline;
    margin-right: 1vw;
    cursor: pointer;
    transition: all .2s ease-in-out;
    color: white;
    ${({ name }) => name === 'visibility' &&`
        border: 1px solid transparent;
        border-radius: 5px;
        padding: .7em;
        background: green;
        &:hover {
            border: 1px solid green;
            background: transparent;
            color: black;
        }
    `}
    ${({ name }) => name === 'delete' &&`
        border: 1px solid transparent;
        border-radius: 5px;
        padding: .7em;
        background: red;
        &:hover {
            border: 1px solid red;
            background: transparent;
            color: black;
        }
    `}
`;

export default EmpresasTable;
