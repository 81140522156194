import React, { useEffect, useState } from 'react';
import {
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormInput,
    Button,
    FormSelect,
} from 'shards-react';
import axios from 'axios';
import { showToast } from '../../helpers/toast';
import { cookies } from '../../helpers/createCookies';

// El token hay que cogerlo de las cookies
const initialState = {
    token: cookies.get('token'),
    nombre: '',
    apellidos: '',
    empresa: '',
    dni: '',
    tipo_trabajador: '1',
    telefono: '',
    mail: '',
};

const AddTrabajador = () => {
    const [datosTrabajador, setDatosTrabajador] = useState(initialState);
    const [tiposTrabajador, setTiposTrabajador] = useState([]);
    useEffect(() => {
        const source = axios.CancelToken.source();
        axios.get(`/tipoTrabajadores?token=${cookies.get('token')}`, { cancelToken: source.token }).then((data) => {
            setTiposTrabajador(data.data);
        }).catch((err) => {
            console.log(err);
          });
        return () => {
            source.cancel();
        }
    }, []);
    const handleOnChange = (e) => {
        e.preventDefault();
        setDatosTrabajador({
            ...datosTrabajador,
            [e.target.name]: e.target.value,
        });
    }
    const handleOnClick = async (e) => {
        e.preventDefault();
        console.log(datosTrabajador);
        axios.post('/addTrabajador', datosTrabajador).then((data) => {
            showToast('ok', '¡Trabajador creado correctamente!');
            setDatosTrabajador(initialState);
        }).catch((err) => {
            showToast('err', err.response.statusText);
        });
    }
    return (
        <ListGroup flush>
        <ListGroupItem className='p-3'>
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col md='6' className='form-group'>
                    <label htmlFor='nombre'>Nombre</label>
                    <FormInput
                        id='nombre'
                        name='nombre'
                        type='text'
                        placeholder='Nombre'
                        value={datosTrabajador.nombre}
                        onChange={handleOnChange}
                    />
                  </Col>
                  <Col md='6'>
                    <label htmlFor='apellidos'>Apellidos</label>
                    <FormInput
                      id='apellidos'
                      name='apellidos'
                      type='text'
                      placeholder='Apellidos'
                      value={datosTrabajador.apellidos}
                      onChange={handleOnChange}
                    />
                  </Col>
                </Row>
                <Row form>
                    <Col>
                        <label htmlFor='dni'>DNI</label>
                        <FormInput
                            id='dni'
                            name='dni'
                            type='text'
                            placeholder='DNI'
                            value={datosTrabajador.dni}
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col>
                    <label htmlFor='tipo_trabajador'>Tipo trabajador</label>
                        <FormSelect
                            id='tipo_trabajador'
                            name='tipo_trabajador'
                            type='text'
                            placeholder='Tipo cliente'
                            value={datosTrabajador.tipo_trabajador}
                            onChange={handleOnChange}
                        >
                            {
                                tiposTrabajador.map(({id, tipo_trabajador}, index) => (
                                    <option key={index} id={id} value={id}>{tipo_trabajador}</option>
                                ))
                            }
                        </FormSelect>
                    </Col>
                </Row>
                <br />
                <Row form>
                    <Col>
                        <label htmlFor='telefono'>Teléfono</label>
                        <FormInput
                            id='telefono'
                            name='telefono'
                            type='number'
                            placeholder='Teléfono'
                            value={datosTrabajador.telefono}
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col>
                        <label htmlFor='mail'>Email</label>
                        <FormInput
                            id='mail'
                            name='mail'
                            type='email'
                            placeholder='Email'
                            value={datosTrabajador.mail}
                            onChange={handleOnChange}
                        />
                    </Col>
                </Row>
                <br />
                <Button type='submit' onClick={handleOnClick}>Crear nuevo trabajdor</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    )
}

export default AddTrabajador;
