import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import UsersOverview from "./../components/blog/UsersOverview";
import UsersByDevice from "./../components/blog/UsersByDevice";
import NewDraft from "./../components/blog/NewDraft";
import Discussions from "./../components/blog/Discussions";
import TopReferrals from "./../components/common/TopReferrals";

import Logo from '../assets/logo_d10net.png'
import { cookies } from "../helpers/createCookies";

const BlogOverview = ({ smallStats, history }) => (
  <Container fluid className="main-content-container px-1">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Vista general" subtitle="Panel de control" className="text-sm-left mb-3" />
    </Row>

    {/* Small Stats Blocks */}
    <Row>
      {smallStats.slice(0, 4).map((stats, idx) => (
        <Col className="col-lg mb-4" key={idx} {...stats.attrs} style={{ cursor: 'pointer' }} onClick={() => history.push(stats.route)}>
          <SmallStats
            id={`small-stats-${idx}`}
            variation="1"
            chartData={stats.datasets}
            chartLabels={stats.chartLabels}
            label={stats.label}
            value={stats.value}
            percentage={stats.percentage}
            increase={stats.increase}
            decrease={stats.decrease}
          />
        </Col>
      ))}
    </Row>

    <Row>
      <Col lg="4" md="12" sm="12" className="mb-4">
        <UsersOverview title='Ingresos'/>
      </Col>
      <Col lg="4" md="6" sm="12" className="mb-4">
        <img src={Logo} width='350' alt='Logo empresa' />
      </Col>
      <Col lg="4" md="6" sm="12" className="mb-4">
        <UsersByDevice title='Gastos' />
      </Col>
      { /*
      <Col lg="4" md="6" sm="12" className="mb-4">
        <NewDraft />
      </Col>
      <Col lg="5" md="12" sm="12" className="mb-4">
        <Discussions />
      </Col>
      <Col lg="3" md="12" sm="12" className="mb-4">
        <TopReferrals />
      </Col>
      */ }
    </Row>
    <Row>
      {smallStats.slice(4).map((stats, idx) => (
        <Col className="col-lg mb-4" key={idx} {...stats.attrs} style={{ cursor: 'pointer' }} onClick={() => history.push(stats.route)}>
          <SmallStats
            id={`small-stats-${idx}`}
            variation="1"
            chartData={stats.datasets}
            chartLabels={stats.chartLabels}
            label={stats.label}
            value={stats.value}
            percentage={stats.percentage}
            increase={stats.increase}
            decrease={stats.decrease}
          />
        </Col>
      ))}
    </Row>
  </Container>
);

BlogOverview.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

let principal_menus = []

console.log(parseInt(cookies.get('tipo_usuario')))

if(parseInt(cookies.get('tipo_usuario')) === 1) {
  principal_menus = [
    {
      label: "Clientes",
      value: "2,390",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ],
      route: '/blog-posts',
    },
    {
      label: "Planficación de trabajos",
      value: "123 | 45 | 32",
      percentage: "",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ],
      route: '/ordenes-de-trabajo',
    },
    {
      label: "RRHH",
      value: "8,147",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ],
      route: '/trabajadores',
    },
    {
      label: "Gestión Almacenes",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ],
      route: '/vehiculos',
    },
    {
      label: "CRM",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(157,0,255,0.1)",
          borderColor: "rgb(57,0,255)",
          data: [1, 7, 6, 8, 4, 4, 2]
        }
      ]
    },
    {
      label: "Documentos",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(181,110,16,0.1)",
          borderColor: "rgb(181,110,16)",
          data: [1, 2, 6, 3, 2, 5, 7]
        }
      ],
      route: '/documentos',
    },
    {
      label: "Administración",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(130,255,188,0.1)",
          borderColor: "rgb(130,255,188)",
          data: [8, 5, 6, 2, 1, 3, 9]
        }
      ]
    },
    {
      label: "Att Cliente",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(250,117,255,0.1)",
          borderColor: "rgb(250,117,255)",
          data: [7, 5, 6, 4, 6, 5, 7]
        }
      ]
    },
  ]
} else {
  principal_menus = [
    {
      label: "Tareas",
      value: "2,390",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ],
      route: '/ordenes-asignadas',
    },
    {
      label: "Mis documentos",
      value: "123 | 45 | 32",
      percentage: "",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ],
      route: '/documentos',
    },
    {
      label: "Calendario",
      value: "8,147",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ],
      route: '/calendario',
    },
    {
      label: "Perfil",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ],
      route: '/user-profile-lite',
    },
  ]
}

BlogOverview.defaultProps = {
  smallStats: principal_menus,
};

export default BlogOverview;
