import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import axios from "axios";
import { cookies } from "../../helpers/createCookies";
import { showToast } from "../../helpers/toast";

const UserAccountDetails = ({ title }) => {
  const [data, setData] = useState({
    token: cookies.get('token'),
    nombre: cookies.get('nombre'),
    apellidos: cookies.get('apellidos'),
    passwd: '',
  });
  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }
  const handleOnClick = (e) => {
    e.preventDefault();
    axios.post('/updateDatosTrabajador', data).then((data) => {
      if(data.error) {
        showToast('err', 'Error al actualizar los datos.');
        return;
      }
      showToast('ok', 'Datos actualizados correctamente.');
    })
  }
  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  {/* First Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feFirstName">Nombre</label>
                    <FormInput
                      id="feFirstName"
                      placeholder="First Name"
                      value={data.nombre}
                      name='nombre'
                      onChange={(e) => handleOnChange(e)}
                    />
                  </Col>
                  {/* Last Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feLastName">Apellidos</label>
                    <FormInput
                      id="feLastName"
                      placeholder="Last Name"
                      value={data.apellidos}
                      name='apellidos'
                      onChange={(e) => handleOnChange(e)}
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Email */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feEmail">Email</label>
                    <FormInput
                      type="email"
                      id="feEmail"
                      placeholder="Email Address"
                      value={cookies.get('mail')}
                      onChange={() => {}}
                      autoComplete="email"
                      disabled
                    />
                  </Col>
                  {/* Password */}
                  <Col md="6" className="form-group">
                    <label htmlFor="fePassword">Nueva contraseña</label>
                    <FormInput
                      type="password"
                      id="fePassword"
                      value={data.passwd}
                      name='passwd'
                      onChange={(e) => handleOnChange(e)}
                      autoComplete="current-password"
                    />
                  </Col>
                </Row>
                {/* {<FormGroup>
                  <label htmlFor="feAddress">Dirección</label>
                  <FormInput
                    id="feAddress"
                    placeholder="Address"
                    value="Ronda 8"
                    onChange={() => {}}
                  />
                </FormGroup>
                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="feCity">Ciudad</label>
                    <FormInput
                      id="feCity"
                      placeholder="Ciudad"
                      onChange={() => {}}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="feInputState">Provincia</label>
                    <FormSelect id="feInputState">
                      <option>Elige...</option>
                      <option>...</option>
                    </FormSelect>
                  </Col>
                  <Col md="2" className="form-group">
                    <label htmlFor="feZipCode">CP</label>
                    <FormInput
                      id="feZipCode"
                      placeholder="Código postal"
                      onChange={() => {}}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feDescription">Descripción</label>
                    <FormTextarea id="feDescription" rows="5" />
                  </Col>
                </Row>} */}
                <Button theme="accent" onClick={handleOnClick}>Actualizar cuenta</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

export default UserAccountDetails;
