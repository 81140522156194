import { createSlice } from '@reduxjs/toolkit'

export const trabajadoresReducer = createSlice({
  name: 'trabajadores',
  initialState: {
    value: {},
    total: 0,
  },
  reducers: {
    update: (state, action) => {
      state.value = action.payload;
      state.total = Object.values(state.value).reduce((a, item) => a + item, 0);
    },
    reset: (state, action) => {
      state.value = [];
    }
  },
})

// Action creators are generated for each case reducer function
export const { update, reset } = trabajadoresReducer.actions;

export const trabajadores = (state) => state.trabajadores.value;
export const total = (state) => state.trabajadores.total;

export default trabajadoresReducer.reducer;