import React from "react";
import { Nav, DropdownToggle, DropdownItem, DropdownMenu } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import { cookies } from "../../../helpers/createCookies";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: Store.getSidebarItems(),
      nonAdminItems: Store.getSidebarNonAdminNavItems(),
      navItemsTrabajo: Store.getSidebarTrabajosItems(),
      navItemsRRHH: Store.getSidebarRRHHItems(),
      navItemsClientes: Store.getSidebarClientesItems(),
      navItemsLogistica: Store.getSidebarLogisticaItems(),
      navItemsDocumentos: Store.getSidebarDocumentosItems(),
      tipo_user: parseInt(cookies.get("tipo_usuario")),
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(),
      nonAdminItems: Store.getSidebarNonAdminNavItems(),
    });
  }

  render() {
    const {
      navItems: items,
      nonAdminItems: nonAdminNavItems,
      navItemsTrabajo,
      navItemsRRHH,
      navItemsClientes,
      navItemsLogistica,
      navItemsDocumentos,
    } = this.state;
    const path = window.location.pathname.split("/")[1];
    const tipo_usuario = parseInt(cookies.get('tipo_usuario'));
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {path === "empresas-trabajo" ||
          path === "ordenes-de-trabajo" ||
          path === "orden-de-trabajo" ||
          path === "ordenes-asignadas" ||
          path === "ordenes-pendientes-de-validar" ||
          path === "ordenes-cerradas" ||
          path === "tarea"
            ? navItemsTrabajo.map((item, idx) => (
                <SidebarNavItem key={idx} item={item} />
              ))
            : path === "ficha-trabajador" ||
              path === "trabajadores" ||
              path === "nuevo-trabajador" ||
              path === "ausencias"
            ? navItemsRRHH.map((item, idx) => (
                <SidebarNavItem key={idx} item={item} />
              ))
            : path === "blog-posts" ||
              path === "ficha-cliente" ||
              path === "add-new-post"
            ? navItemsClientes.map((item, idx) => (
                <SidebarNavItem key={idx} item={item} />
              ))
            : path === "vehiculos" ||
              path === "nuevo-vehiculo" ||
              path === "ficha-vehiculo" ||
              path === "sedes" ||
              path === "gestionar-sede"
            ? navItemsLogistica.map((item, idx) => (
                <SidebarNavItem key={idx} item={item} />
              ))
            : path === "documentos"
            ? navItemsDocumentos.map((item, idx) => (
                <SidebarNavItem key={idx} item={item} />
              ))
            : items.map((item, idx) => (
                <SidebarNavItem key={idx} item={item} />
              ))}
          {}
        </Nav>
      </div>
    );
  }
}

export default SidebarNavItems;
