import React from 'react'
import OTs from './OTs'
import OTsCliente from './OTsCliente'

const OTsClienteCheck = (props) => {
    return (
        <>
            {
            !props.match.params.id ?
                    <OTs props={props} />
                :
                    <OTsCliente props={props} />
            }
        </>
    )
}

export default OTsClienteCheck
