import React from "react";
import { Container, Row, Col } from "shards-react";
import { ToastContainer, Slide } from 'react-toastify';

import PageTitle from "../components/common/PageTitle";
import AddCliente from "../components/add-new-post/AddCliente";

const AddNewPost = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Nuevo cliente" subtitle="Añadir" className="text-sm-left" />
    </Row>

    <Row>
      {/* Editor */}

      {/* Sidebar Widgets */}
      <Col lg="12" md="12">
        <AddCliente />
      </Col>
    </Row>
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      transition={Slide}
    />
  </Container>
);

export default AddNewPost;
