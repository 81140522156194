import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { trabajadores, total } from '../../reducers/trabajadoresReducer';

const AccionesTrabajadores = () => {
    const trabajadoresSeleccionados = useSelector(trabajadores);
    const totalSeleccionados = useSelector(total);
    return (
        <Container total={totalSeleccionados} className='acciones'>
            <IconContainer>
                <Icon className='material-icons'>groups</Icon>
                <p>Añadir a un equipo</p>
            </IconContainer>
            <IconContainer>
                <Icon className='material-icons'>delete_outline</Icon>
                <p>Eliminar trabajadores</p>
            </IconContainer>
        </Container>
    )
}

const Container = styled.div`
    ${({ total }) => total >= 2 && css`
        height: inherit;
        flex-grow: .2;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-self: center;
        
    `}
    ${({ total }) => total < 2 && css`
        i, p {
            display: none;
        }
        flex-grow: 0;
    `}
    i {
        font-size: 5em;
    }
    transition: all .8s linear;
`;

const IconContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 1vw;
`;

const Icon = styled.i.attrs((props) => ({
    className: props.className || 'material-icons',
}))`
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .2s ease-in-out;
    color: black;
    align-items: center;
    justify-content: center;
    align-self: center;
`;

export default AccionesTrabajadores;
