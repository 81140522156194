import React, { useState, useEffect } from "react";
import { Container, Row } from "shards-react";
import { ToastContainer, Slide } from 'react-toastify';
import PageTitle from "../components/common/PageTitle";
import GestionAusencias from "../components/Ausencias/GestionAusencias";

const GestionAusenciasView = ({props}) => {
    return (
        <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title='Ausencias' subtitle="Gestión de" className="text-sm-left" />
        </Row>
        <GestionAusencias props={props} />
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            transition={Slide}
        />
    </Container>
    )
}

export default GestionAusenciasView;
