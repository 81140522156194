// eslint-disable-next-line import/no-anonymous-default-export
export default function() {
  return [
    {
      title: "Panel de control",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
    },
    {
      title: "Perfil",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    },
    {
      title: "Notificaciones",
      htmlBefore: '<i class="material-icons">error</i>',
      to: "/errors",
    },
    {
      title: 'Tareas',
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: '/tareas',
    },
  ];
}
