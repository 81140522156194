import React, { useState, useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Col,
} from "shards-react";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { update, total } from '../../reducers/trabajadoresReducer';

import { showToast } from '../../helpers/toast';
import styled, { css } from 'styled-components';
import AccionesTrabajadores from './AccionesTrabajadores';
import { cookies } from '../../helpers/createCookies';

const TrabajadoresCards = ({props}) => {
    const dispatch = useDispatch();
    const totalSeleccionados = useSelector(total);
    const [trabajadores, setTrabajadores] = useState([]);
    const [trabajadoresSeleccionados, setTrabajadoresSeleccionados] = useState({
        
    });
    useEffect(() => {
        getTrabajadores();
    }, []);
    const getTrabajadores = () => {
        axios.get(`/getTrabajadores?token=${cookies.get('token')}`).then(({data}) => {
            setTrabajadores(data.data);
        });
    }
    const verFicha = (e) => {
        props.history.push(`/ficha-trabajador/${e.target.parentNode.parentNode.getAttribute('id')}`);
    }
    const eliminarTrabajador = (e) => {
        e.preventDefault();
        // El token hay que cogerlo de las cookies
        const datos = {
            token: cookies.get('token'),
            id: e.target.parentNode.parentNode.getAttribute('id'),
        }
        axios.post('/eliminarTrabajador', datos).then((data) => {
            showToast('ok', '¡Trabajador eliminado correctamente!');
            getTrabajadores();
        }).catch((err) => {
            showToast('err', err.response.statusText);
        });
    }
    const seleccionarTrabajador = (e) => {
        const { id } = e.target.parentNode;
        if(!id) return;
        let temp = {...trabajadoresSeleccionados};
        temp[id] = !temp[id];
        setTrabajadoresSeleccionados(temp);
        dispatch(update(temp));
    }
    return (
        <Container>
           <Table total={totalSeleccionados}>
            <Thead>
                <tr>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Móvil</th>
                    <th>Tipo trabajador</th>
                    <th>Acciones</th>
                </tr>
            </Thead>
            <Tbody>
                {
                    trabajadores.map(({id, nombre, mail, telefono, tipo_trabajador}, index) => {
                        return (
                            <Tr key={index} onClick={seleccionarTrabajador} id={id} active={trabajadoresSeleccionados[id]}>
                                <td data-label="Number">{nombre}</td>
                                <td data-label="Name">{mail}</td>
                                <td data-label="Phone">{telefono}</td>
                                <td data-label="Date">{tipo_trabajador}</td>
                                <td data-label="Company">
                                    <Icon className='material-icons' name='description' onClick={verFicha}>description</Icon>
                                    <Icon className='material-icons' name='delete' onClick={eliminarTrabajador}>delete</Icon>
                                </td>
                            </Tr>
                        )
                    })
                }
            </Tbody>
        </Table>
        <AccionesTrabajadores />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
`;

const Table = styled.table`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: #fff;
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
    padding: 1em;
    @media screen and (max-width: 640px) {
        border: 0;
    }
`;

const Thead = styled.thead`
    color: black;
    border-bottom: 1px solid #ccc;
    tr {
        display: flex;
    }
    tr th {
        display: flex;
        flex: 1;
        padding: .5em;
    }
    @media screen and (max-width: 640px) {
        display: none;
    }
`;

const Tbody = styled.tbody`
    overflow: auto;
    height: 70vh;
    td {
        @media screen and (max-width: 640px) {
            flex-direction: column;
        }
    }
    tr td {
        display: flex;
        flex: 1;
        padding: 1.5em;
        align-items: center;
    }
    tr td i {
        font-size: 2em;
    }
    tr td+td {
        @media screen and (max-width: 640px) {
            border-top: 1px solid #ccc;
        }
    }
    tr td:before {
        @media screen and (max-width: 640px) {
            display: flex;
            align-items: center;
            margin: -.5em -.5em .75em -.5em;
            padding: .5em;
            content: attr(data-label);
            color: #fff;
            background: #009485;
        }
    }
`;

const Tr = styled.tr`
    border-bottom: 1px solid #ccc;
    display: flex;
    @media screen and (max-width: 640px) {
        flex-direction: column;
        margin: 1em;
        border: 1px solid #ccc;
    }
    ${({ active }) => active &&`
        background: #8aa5ff;
        border-radius: 8px;
    `}
`;

const Icon = styled.i.attrs((props) => ({
    className: props.className || 'material-icons',
}))`
    display: flex;
    margin-right: 1vw;
    cursor: pointer;
    transition: all .2s ease-in-out;
    color: white;
    align-items: center;
    justify-content: center;
    align-self: center;
    ${({ name }) => name === 'description' &&`
        border: 1px solid transparent;
        border-radius: 5px;
        padding: .15em;
        background: green;
        &:hover {
            border: 1px solid green;
            background: transparent;
            color: black;
        }
    `}
    ${({ name }) => name === 'delete' &&`
        border: 1px solid transparent;
        border-radius: 5px;
        padding: .15em;
        background: red;
        &:hover {
            border: 1px solid red;
            background: transparent;
            color: black;
        }
    `}
`;


export default TrabajadoresCards;
