import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";
import { cookies } from "../helpers/createCookies";
import { Redirect } from "react-router-dom";

const DefaultLayout = ({ children, noNavbar, noFooter, noMainSidebar, login }) => {
  if(!cookies.get('token') && !login) return <Redirect to='/iniciar-sesion' />;
  return (
    <>
    {
      login ? 
      <Container>
        {children}
      </Container>
      :
      <Container fluid>
        <Row>
          { !noMainSidebar && <MainSidebar /> }
          <Col
            className="main-content p-0"
            lg={{ size: 10, offset: 2 }}
            md={{ size: 9, offset: 3 }}
            sm="12"
            tag="main"
          >
            { !noNavbar && <MainNavbar /> }
            {children}
            { !noFooter && <MainFooter copyright='Copyright © 2021 DesignRevision' /> }
          </Col>
        </Row>
      </Container>
    }
    </>
  )
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
