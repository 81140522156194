import { createSlice } from '@reduxjs/toolkit'

export const empresaTrabajoReducer = createSlice({
  name: 'empresaTrabajo',
  initialState: {
    id: null
  },
  reducers: {
    updateId: (state, action) => {
      state.id = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateId } = empresaTrabajoReducer.actions;

export const id = (state) => state.empresaTrabajo.id;

export default empresaTrabajoReducer.reducer;