import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { cookies } from "../../helpers/createCookies";
import { showToast } from "../../helpers/toast";
const NuevoVehiculoView = ({ props }) => {
  const [data, setData] = useState({
    token: cookies.get("token"),
    nombre: '',
    matricula: '',
    fecha_matriculacion: '',
  });
  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleOnClick = (e) => {
    e.preventDefault();
    axios
      .post("/nuevoVehiculo", data)
      .then((data) => {
        setData({
            token: cookies.get('token'),
            nombre: '',
            matricula: '',
            fecha_matriculacion: '',
        })
        showToast('ok', 'Vehículo creado correctamente');
      })
      .catch((err) => {
        console.log(err);
        showToast('err', 'Error al crear el vehículo');
      });
  };
  return (
    <Container>
      <InputsContainer>
        <InputText
          type="text"
          name="nombre"
          placeholder="Nombre"
          value={data.nombre}
          onChange={handleOnChange}
        />
        <InputText
          type="text"
          name="matricula"
          placeholder="Matrícula"
          value={data.matricula}
          onChange={handleOnChange}
        />
        <InputText
          type="text"
          name="fecha_matriculacion"
          placeholder="Fecha"
          value={data.fecha_matriculacion}
          onChange={handleOnChange}
        />
      </InputsContainer>
      <CreateVehicle onClick={handleOnClick}>Guardar</CreateVehicle>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InputsContainer = styled.div`
  display: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2em;
`;

const InputText = styled.input`
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 2.5em;
  width: 33%;
`;

const CreateVehicle = styled.button`
  background-color: transparent;
  border: 1px solid green;
  border-radius: 5px;
  color: green;
  width: 100%;
  height: 3em;
  transition: 0.25s all ease-in-out;
  &:hover {
    background-color: green;
    color: white;
  }
`;

export default NuevoVehiculoView;
