import React from "react";
import { Container, Row } from "shards-react";
import { ToastContainer, Slide } from 'react-toastify';
import PageTitle from "../components/common/PageTitle";
import Documentos from "../components/documentos/Documentos";


const DocumentosView = ({props}) => {
    return (
        <Container fluid className="main-content-container px-4 pb-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Documentos" subtitle="Todos los" className="text-sm-left" />
            </Row>
            <Documentos />
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                transition={Slide}
            />
        </Container>
    )
}

export default DocumentosView
