import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput,
  Row,
  Col,
  ModalFooter,
  FormTextarea,
  Form,
  FormGroup,
} from "shards-react";
import axios from "axios";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { dataOt } from "../../reducers/otsReducer";
import "../../assets/calendar-data.css";
import { cookies } from "../../helpers/createCookies";
import { showToast } from "../../helpers/toast";
import styled from "styled-components";
import Upload from "../../assets/upload.svg";

const TareaSeleccionada = ({ props }) => {
  const data = useSelector(dataOt);
  const [ot, setOt] = useState([]);
  const [tareasPrevias, setTareasPrevias] = useState([]);
  const [desplegableTareas, setDesplegableTareas] = useState(false);
  const [tareaBusqueda, setTareaBusqueda] = useState("");
  const [trabajos, setTrabajos] = useState([]);
  const [itemsPreciario, setItemsPreciario] = useState([]);
  const [modalAddFotos, setModalAddFotos] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState({ files: [] });
  const [datosOt, setDatosOt] = useState({
    cifEmpresa: "",
    direccionEmpresa: "",
    sedeEmpresa: "",
    cliente: "",
    CIF: "",
    telefono: "",
    direccion: "",
    cp: "",
    provincia: "",
  });
  const [totalInputs, setTotalInputs] = useState([0]);
  const [nombreFotos, setNombreFotos] = useState({
    cr_frontal: "",
    cr_abierta: "",
    cr_panoramica: "",
    fist_frontal: "",
    fist_abierta: "",
    fist_panoramica: "",
    dvcao_frontal: "",
    dvcao_abierta: "",
    dvcao_panoramica: "",
    na_abierta: "",
    na_entradascable: "",
    na_salidascable: "",
  });
  const [comentarioTecnico, setComentarioTecnico] = useState([]);
  const [lastComentario, setLastComentario] = useState(0);
  const [sameDay, setSameDay] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const id = props.match.params.id;
    const source = axios.CancelToken.source();
    axios
      .get(`/getOt?token=${cookies.get("token")}&id=${id}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        console.log(data);
        setOt(data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/getTareasOtTodas?token=${cookies.get("token")}&id=${id}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        setTareasPrevias(data);
      })
      .catch((err) => {
        console.log(err);
      });
    getComentarios();
    return () => {
      source.cancel();
    };
  }, []);

  const getComentarios = (source = axios.CancelToken.source()) => {
    axios
      .get(
        `/getComentarioTecnico?token=${cookies.get("token")}&id_ot=${
          props.match.params.id
        }`,
        {
          cancelToken: source.token,
        }
      )
      .then(({ data }) => {
        setComentarioTecnico(data);
        console.log(data);
        const lastComentario = data
          .map((i) => parseInt(i.tmst))
          .reduce((acc, val) => (acc < val ? acc : val));
        console.log(lastComentario);
        setLastComentario(data.length > 0 ? lastComentario : 0);
        console.log(lastComentario);
        createComentario(lastComentario);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createComentario = (lastComentario) => {
    let d = new Date();
    let d_lastComentario = new Date(lastComentario);
    if (
      d.getDate() === d_lastComentario.getDate() &&
      d.getMonth() === d_lastComentario.getMonth() &&
      d.getFullYear() === d_lastComentario.getFullYear()
    ) {
      setSameDay(true);
      return;
    }
    axios
      .post("/createComentarioTecnico", {
        token: cookies.get("token"),
        id_ot: props.match.params.id,
        comentario: "",
        tmst: d.getTime(),
      })
      .then(({ data }) => {
        setSameDay(false);
        getComentarios();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnChange = (e) => {
    setDatosOt({
      ...datosOt,
      [e.target.name]: e.target.value,
    });
  };
  const saveOt = (e) => {
    e.preventDefault();
    // El token hay que cogerlo de las cookies
    const datos = {
      token: cookies.get("token"),
      id: props.match.params.id,
      trabajos: trabajos,
      comentario_tecnico: ot.comentario_tecnico,
      cliente: data.id_cliente,
    };

    console.log(datos);

    axios
      .post("/updateOtTecnico", datos)
      .then((data) => {
        if (!data.error) {
          showToast("ok", "Datos actualizados correctamente.");
        } else {
          showToast("err", "Error al actualizar los datos.");
        }
      })
      .catch((err) => {
        showToast("err", "Error al actualizar los datos.");
      });
  };
  const descargarArchivos = (e) => {
    e.preventDefault();
    showToast("", "Preparando los archivos para la descarga...");
    axios
      .get(
        `/descargarArchivos?token=${cookies.get("token")}&id=${
          props.match.params.id
        }`,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.className = "descarga_archivo";
        link.setAttribute("download", "archivo.zip");
        document.body.appendChild(link);
        link.click();
        document.getElementsByClassName("descarga_archivo")[0].remove();
      });
  };
  const busquedaItem = (e) => {
    setTareaBusqueda(e.target.value);
    e.target.value.length
      ? setDesplegableTareas(true)
      : setDesplegableTareas(false);
    axios
      .get(
        `/busquedaItem?token=${cookies.get("token")}&id=${
          data.id_cliente
        }&busqueda=${e.target.value}`
      )
      .then(({ data }) => {
        setItemsPreciario(data);
      });
  };
  const selectTarea = (e, descripcion, codigo, precio, unidad) => {
    setTrabajos([
      ...trabajos,
      {
        id: e.target.id,
        descripcion,
        codigo,
        precio,
        unidad,
        cantidad: 1,
      },
    ]);
    setTareaBusqueda("");
    setDesplegableTareas(false);
  };
  const changeComentario = (e) => {
    setOt({
      ...ot,
      comentario_tecnico: e.target.value,
    });
    console.log(e.target.value);
    axios
      .post("/updateComentarioTecnico", {
        token: cookies.get("token"),
        id_comentario: e.target.id,
        comentario: e.target.value,
      })
      .then(({ data }) => {
        console.log(data);
      });
  };
  const reduceCantidadPlantilla = (e, index) => {
    let arr = [...tareasPrevias];
    if (arr[index].cantidad !== 1) arr[index].cantidad--;
    setTareasPrevias(arr);
  };
  const addCantidadPlantilla = (e, index) => {
    let arr = [...tareasPrevias];
    arr[index].cantidad++;
    setTareasPrevias(arr);
  };
  const reduceCantidadTrabajo = (e, index) => {
    let arr = [...trabajos];
    arr[index].cantidad--;
    setTrabajos(arr);
  };
  const addCantidadTrabajo = (e, index) => {
    let arr = [...trabajos];
    arr[index].cantidad++;
    setTrabajos(arr);
  };
  const closeModalAddFotos = (e) => {
    setModalAddFotos(false);
  };
  const saveFotos = (e) => {
    e.preventDefault();
    var formData = new FormData();
    const arrCopy = [...selectedPhotos.files];
    for (const key of Object.keys(arrCopy)) {
      formData.append(
        "selectedPhotos",
        arrCopy[key],
        `${cookies.get("token")} ${data.id}-${arrCopy[key].name}`
      );
    }
    axios.post("/uploadFotos", formData, {}).then((res) => {
      console.log(res.data);
    });

    closeModalAddFotos(e);
  };
  const addPhotos = (e) => {
    if (!nombreFotos[e.target.id]) {
      showToast(
        "warn",
        "Antes de subir la foto, tienes que escribir el nombre"
      );
      return;
    }
    let formData = new FormData();
    formData.append(
      "selectedPhotos",
      e.target.files[0],
      `${cookies.get("token")} ${data.id}-${nombreFotos[e.target.id]}.jpg`
    );
    setSelectedPhotos({ files: e.target.files });
    axios
      .post("/uploadFotos", formData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOnChangeFotosNombre = (e) => {
    setNombreFotos({
      ...nombreFotos,
      [e.target.name]: e.target.value.replace(/\s+/g, ""),
    });
  };
  return (
    <>
      <Row>
        <Col>
          <FormInput
            id="cliente"
            name="cliente"
            placeholder="Cliente"
            value={data.nombre_cliente}
            disabled
          />
        </Col>
        <Col>
          <FormInput id="CIF" name="CIF" value={data.cif} disabled />
        </Col>
        <Col>
          <FormInput
            id="telefono"
            name="telefono"
            value={data.telefono}
            disabled
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <FormInput
            id="direccion"
            name="direccion"
            placeholder="Dirección"
            value={data.direccion}
            disabled
          />
        </Col>
        <Col>
          <FormInput
            id="CP"
            name="CP"
            placeholder="Código Postal"
            value={data.cp}
            disabled
          />
        </Col>
        <Col>
          <FormInput
            id="provincia"
            name="provincia"
            placeholder="Provincia"
            value={data.provincia}
            disabled
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <p style={{ textAlign: "justify" }}>
            <b>
              Trabajos a realizar:
              <br />
            </b>
            <TrabajosRealizar>
              {
                <table className="table mb-0" style={{ fontSize: ".85em" }}>
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Código
                      </th>
                      <th scope="col" className="border-0">
                        Descripción
                      </th>
                      <th scope="col" className="border-0">
                        Cantidad
                      </th>
                      <th scope="col" className="border-0">
                        Puntos unitarios
                      </th>
                      <th scope="col" className="border-0">
                        Total puntos
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tareasPrevias.length &&
                      tareasPrevias.map(
                        (
                          { id, codigo, descripcion, cantidad, unidad, puntos },
                          index
                        ) => (
                          <tr key={index} id={id}>
                            <th>{codigo}</th>
                            <th>{descripcion}</th>
                            <th>
                              <SpanCantidad
                                onClick={(e) =>
                                  reduceCantidadPlantilla(e, index)
                                }
                              >
                                -
                              </SpanCantidad>
                              &nbsp;{cantidad}&nbsp;
                              <SpanCantidad
                                onClick={(e) => addCantidadPlantilla(e, index)}
                              >
                                +
                              </SpanCantidad>
                            </th>
                            <th>{puntos}</th>
                            <th>{(puntos * cantidad).toFixed(2)}</th>
                          </tr>
                        )
                      )}
                    {trabajos.length &&
                      trabajos.map(
                        (
                          { id, codigo, descripcion, cantidad, unidad, puntos },
                          index
                        ) => (
                          <tr key={index} id={id}>
                            <th>{codigo}</th>
                            <th>{descripcion}</th>
                            <th>
                              <SpanCantidad
                                onClick={(e) => reduceCantidadTrabajo(e, index)}
                              >
                                -
                              </SpanCantidad>
                              &nbsp;{cantidad}&nbsp;
                              <SpanCantidad
                                onClick={(e) => addCantidadTrabajo(e, index)}
                              >
                                +
                              </SpanCantidad>
                            </th>
                            <th>{puntos}</th>
                            <th>{(puntos * cantidad).toFixed(2)}</th>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              }
            </TrabajosRealizar>
            <FormInput
              placeholder="Añadir tarea..."
              value={tareaBusqueda}
              onChange={busquedaItem}
            />
            {desplegableTareas && (
              <ShowTareas>
                {itemsPreciario.map(
                  ({ id, descripcion, codigo, precio, unidad }, index) => (
                    <TareaData
                      key={index}
                      id={id}
                      onClick={(e) =>
                        selectTarea(e, descripcion, codigo, precio, unidad)
                      }
                    >
                      {descripcion}
                    </TareaData>
                  )
                )}
              </ShowTareas>
            )}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p style={{ textAlign: "justify" }}>
            <b>
              Datos de actuación:
              <br />
            </b>{" "}
            <MsgOt>
              {data.asunto}
              <br />
              {data.mensaje}
            </MsgOt>
          </p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col></Col>
      </Row>
      <br />
      <Row>
        <Col>
          <FormTextarea
            rows="5"
            placeholder="Comentario pre actuación"
            value={data.comentario_administracion}
            disabled
          />
        </Col>
      </Row>
      <br />
      {comentarioTecnico.map((item, index) => (
        <>
          <Row key={index}>
            <Col>
              <FormTextarea
                id={item.id}
                rows="5"
                placeholder="Comentario técnico"
                onChange={changeComentario}
                defaultValue={item.comentario}
                disabled={
                  new Date(parseInt(item.tmst)).getDate() ===
                    new Date().getDate() &&
                  new Date(parseInt(item.tmst)).getMonth() ===
                    new Date().getMonth() &&
                  new Date(parseInt(item.tmst)).getFullYear() ===
                    new Date().getFullYear()
                }
              />
            </Col>
          </Row>
          <br />
        </>
      ))}
      {/* <Row>
        <Col>
          <FormTextarea
            rows="5"
            placeholder="Comentario técnico"
            value={ot.comentario_tecnico}
            onChange={changeComentario}
          />
        </Col>
      </Row> */}
      <Row>
        <Col>
          <p>
            <b>Estado:</b> {data.finalizado === 1 ? "Pendiente" : "Finalizada"}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md="8"></Col>
        <Col>
          <Button
            outline
            theme="primary"
            onClick={() => setModalAddFotos(true)}
          >
            Subir fotos
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button outline theme="dark">
            Finalizar
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button outline theme="info" onClick={descargarArchivos}>
            Descargar archivos
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button theme="success" outline onClick={saveOt}>
            Guardar
          </Button>
        </Col>
      </Row>
      <br />
      <Modal
        open={modalAddFotos}
        toggle={closeModalAddFotos}
        size="lg"
        style={{ overflowX: "auto", height: "700px" }}
      >
        <ModalHeader>Añadir fotos</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <p>CR</p>
              <LabelFile htmlFor="cr_frontal">
                <span>
                  <FileIcon src={Upload} />
                  Frontal
                  <InputFile id="cr_frontal" type="file" onChange={addPhotos} />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="cr_frontal"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <LabelFile htmlFor="cr_abierta">
                <span>
                  <FileIcon src={Upload} />
                  Abierta
                  <InputFile id="cr_abierta" type="file" onChange={addPhotos} />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="cr_abierta"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <LabelFile htmlFor="cr_panoramica">
                <span>
                  <FileIcon src={Upload} />
                  Panoramica
                  <InputFile
                    id="cr_panoramica"
                    type="file"
                    onChange={addPhotos}
                  />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="cr_panoramica"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <p>FIST</p>
              <LabelFile htmlFor="fist_frontal">
                <span>
                  <FileIcon src={Upload} />
                  Frontal
                  <InputFile
                    id="fist_frontal"
                    type="file"
                    onChange={addPhotos}
                  />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="fist_frontal"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <LabelFile htmlFor="fist_abierta">
                <span>
                  <FileIcon src={Upload} />
                  Abierta
                  <InputFile
                    id="fist_abierta"
                    type="file"
                    onChange={addPhotos}
                  />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="fist_abierta"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <LabelFile htmlFor="fist_panoramica">
                <span>
                  <FileIcon src={Upload} />
                  Panoramica
                  <InputFile
                    id="fist_panoramica"
                    type="file"
                    onChange={addPhotos}
                  />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="fist_panoramica"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <p>DVCAO</p>
              <LabelFile htmlFor="dvcao_frontal">
                <span>
                  <FileIcon src={Upload} />
                  Frontal
                  <InputFile
                    id="dvcao_frontal"
                    type="file"
                    onChange={addPhotos}
                  />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="dvcao_frontal"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <LabelFile htmlFor="dvcao_abierta">
                <span>
                  <FileIcon src={Upload} />
                  Abierta
                  <InputFile
                    id="dvcao_abierta"
                    type="file"
                    onChange={addPhotos}
                  />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="dvcao_abierta"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <LabelFile htmlFor="dvcao_panoramica">
                <span>
                  <FileIcon src={Upload} />
                  Panoramica
                  <InputFile
                    id="dvcao_panoramica"
                    type="file"
                    onChange={addPhotos}
                  />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="dvcao_panoramica"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <p>Nombre arqueta</p>
              <LabelFile htmlFor="na_abierta">
                <span>
                  <FileIcon src={Upload} />
                  Abierta
                  <InputFile id="na_abierta" type="file" onChange={addPhotos} />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="na_abierta"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <LabelFile htmlFor="na_entradascable">
                <span>
                  <FileIcon src={Upload} />
                  Entradas cable
                  <InputFile
                    id="na_entradascable"
                    type="file"
                    onChange={addPhotos}
                  />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="na_entradascable"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <LabelFile htmlFor="na_salidascable">
                <span>
                  <FileIcon src={Upload} />
                  Salidas cable
                  <InputFile
                    id="na_salidascable"
                    type="file"
                    onChange={addPhotos}
                  />
                </span>
                <InputFileName
                  type="text"
                  placeholder="Nombre"
                  name="na_salidascable"
                  onChange={handleOnChangeFotosNombre}
                />
              </LabelFile>
              <p>Varios</p>
              {totalInputs.map((input, index) => (
                <LabelFile htmlFor={`varios_${index}`}>
                  <span>
                    <FileIcon src={Upload} />
                    Archivo {index}
                    <InputFile
                      id={`varios_${index}`}
                      type="file"
                      onChange={addPhotos}
                    />
                  </span>
                  <InputFileName type="text" placeholder="Nombre" />
                </LabelFile>
              ))}
              <br />
              <br />
              <Button
                outline
                theme="info"
                onClick={() => setTotalInputs([...totalInputs, 0])}
              >
                Nuevo archivo
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button outline theme="danger" onClick={closeModalAddFotos}>
            Cancelar
          </Button>
          <Button outline onClick={saveFotos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const MsgOt = styled.pre`
  padding: 5px;
  border: 3px solid rgba(0, 0, 0, 1);
  border-radius: 15px;
  text-align: justify;
  max-height: 30vh;
  overflow: auto;
  ::-webkit-scrollbar {
    background: transparent;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1) !important;
    border-radius: 200px;
  }
  ::-webkit-scrollbar-corner {
    background: none;
  }
`;
const TrabajosRealizar = styled.div`
  padding: 5px;
  text-align: justify;
  max-height: 30vh;
  overflow: auto;
  margin-bottom: 5px;
  ::-webkit-scrollbar {
    background: transparent;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1) !important;
    border-radius: 200px;
  }
  ::-webkit-scrollbar-corner {
    background: none;
  }
`;

const Hora = styled.div`
  display: inline-block;
  height: 3.5vh;
  width: 3.5vw;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0.1em;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: orange;
  }
  ${({ active }) =>
    active === "active" &&
    `
        background: orange;
    `}
`;

const ResetHora = styled.span`
  cursor: pointer;
  color: blue;
  &:hover {
    text-decoration: underline;
  }
`;

const SpanCantidad = styled.span`
  cursor: pointer;
  display: inline-block;
  border: 1px solid black;
  border-color: transparent;
  border-radius: 20px;
  width: 1vw;
  text-align: center;
  background-color: rgba(102, 103, 104, 0.2);
`;

const InputFile = styled.input`
  width: 0, 1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const LabelFile = styled.label`
  font-size: 1.05em;
  font-weight: 700;
  color: white;
  background-color: rgba(100, 149, 237, 0.5);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  border-radius: 5px;
  border: 3px solid cornflowerblue;
  color: #417dc1;
  margin-right: 5px;
  width: 13em;
  text-align: center;
`;

const FileIcon = styled.img`
  width: 2em;
  height: 2em;
  margin-right: 5px;
`;

const InputFileName = styled.input`
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
`;

const ShowTareas = styled.div`
  background-color: white;
  max-height: 15vh;
  overflow: auto;
`;

const TareaData = styled.span`
  cursor: pointer;
  font-size: 0.8em;
  display: block;
  border-bottom: 1px solid #eee;
  transition: all 0.1s linear;
  &:hover {
    background-color: #eee;
  }
`;

export default TareaSeleccionada;
