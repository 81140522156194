import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button
} from 'shards-react';
import { ToastContainer, Slide } from 'react-toastify';

import PageTitle from "../components/common/PageTitle";
import TareasData from '../components/Tareas/TareasData';

const Tareas = (props) => {
    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Tareas" subtitle="Mis" className="text-sm-left" />
            </Row>
            <TareasData props={props} />
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                transition={Slide}
            />
        </Container>
    )
}

export default Tareas;
