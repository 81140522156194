// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return [
    {
        title: "Panel de control",
        to: "/blog-overview",
        htmlBefore: '<i class="material-icons">edit</i>',
    },
    {
      title: "Trabajadores",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/trabajadores",
    },
    {
      title: "Nuevo trabajador",
      htmlBefore: '<i class="material-icons">person_add</i>',
      to: "/nuevo-trabajador",
    },
    {
      title: "Ofertas de empleo",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/ofertas-de-empleo",
    },
    {
      title: "Gestión de candidatos",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/gestion-de-candidatos",
    },
    {
      title: "Incorporación",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/incorporacion",
    },
    {
      title: "Expediente digital",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/expediente-digital",
    },
    {
      title: "Registro de jornada",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/registro-de-jornada",
    },
    {
      title: "Ausencias",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/ausencias",
    },
    {
      title: "Nóminas",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/nominas",
    },
  ];
}
