import { createSlice } from '@reduxjs/toolkit'

export const perfilUsuarioReducer = createSlice({
  name: 'perfilUsuario',
  initialState: {
    totalPuntos: null
  },
  reducers: {
    updateTotalPuntos: (state, action) => {
      state.totalPuntos = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateTotalPuntos } = perfilUsuarioReducer.actions;

export const totalPuntos = (state) => state.perfilUsuario.totalPuntos;

export default perfilUsuarioReducer.reducer;