import React, { useState, useEffect } from "react";
import { Container, Row } from "shards-react";
import { ToastContainer, Slide } from 'react-toastify';
import PageTitle from "../components/common/PageTitle";
import axios from 'axios';
import OtsCliente from "../components/ots/OtsCliente";
import { cookies } from "../helpers/createCookies";

const OTsPendientes = (props) => {
    return (
        <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Pendientes" subtitle="Órdenes de trabajo" className="text-sm-left" />
        </Row>
        <OtsCliente props={props} id={-1} nombre={''} tipo_ots={1} />
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            transition={Slide}
        />
    </Container>
    )
}

export default OTsPendientes;
