import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Progress,
  Row
} from "shards-react";
import { cookies } from "../../helpers/createCookies";
import ResumenDias from "./ResumenDias";
import { totalPuntos } from "../../reducers/perfilUsuarioReducer";
import { useSelector } from "react-redux";

const UserDetails = ({ userDetails }) => {
  const totalPuntosTrabajador = useSelector(totalPuntos);
  return (
    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <h4 className="mb-0">{cookies.get('nombre')} {cookies.get('apellidos')}</h4>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <Row>
              <Col>
                <strong className="text-muted d-block mb-2">
                  Puntos
                </strong>
                <h3>{totalPuntosTrabajador}</h3>
              </Col>
              <Col>
                <strong className="text-muted d-block mb-2">
                  Nómina
                </strong>
                <h3>{totalPuntosTrabajador * 5} €</h3>
              </Col>
            </Row>
          </div>
        </ListGroupItem>
        <ListGroupItem className="p-4">
          <strong className="text-muted d-block mb-2">
            Resumen días
          </strong>
          <ResumenDias />
        </ListGroupItem>
      </ListGroup>
    </Card>
)};

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object
};

UserDetails.defaultProps = {
  userDetails: {
    name: `${cookies.get('nombre')} ${cookies.get('apellidos')}`,
    avatar: 'https://media-exp1.licdn.com/dms/image/C4D03AQFM_j0FDeAx1w/profile-displayphoto-shrink_800_800/0/1530520563682?e=1639612800&v=beta&t=XD6OfT3nCtuSShVikWMRL8nfFHGQQjwMCAIcgwgiy-Y',
    jobTitle: "Jefe de proyecto",
    performanceReportTitle: "Progreso",
    performanceReportValue: 76,
    metaTitle: "Descripción",
    metaValue:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?"
  }
};

export default UserDetails;
