// eslint-disable-next-line import/no-anonymous-default-export
export default function() {
  return [
    {
      title: "Panel de control",
      htmlBefore: '<i class="material-icons">edit</i>',
      to: "/blog-overview",
      type_user_allowed: 0,
    },
    {
      title: "Documentos",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/documentos",
      type_user_allowed: 0,
    },
  ];
}
