import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button
} from "shards-react";
import { ToastContainer, Slide } from 'react-toastify';

import PageTitle from "../components/common/PageTitle";
import ClientesData from "../components/Clientes/ClientesData";

const BlogPosts = (props) => {
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Clientes" subtitle="ERP" className="text-sm-left" />
      </Row>
      <ClientesData props={props}/>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        transition={Slide}
      />
    </Container>
  )
}

export default BlogPosts;
