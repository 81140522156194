import { configureStore } from '@reduxjs/toolkit'
import trabajadoresReducer from '../reducers/trabajadoresReducer';
import otsReducer from '../reducers/otsReducer';
import tareaSeleccionadaReducer from '../reducers/tareaSeleccionadaReducer';
import empresaTrabajoReducer from '../reducers/empresaTrabajoReducer';
import perfilUsuarioReducer from '../reducers/perfilUsuarioReducer';

export default configureStore({
  reducer: {
    trabajadores: trabajadoresReducer,
    ots: otsReducer,
    tareaSeleccionada: tareaSeleccionadaReducer,
    empresaTrabajo: empresaTrabajoReducer,
    perfilUsuario: perfilUsuarioReducer,
  },
});