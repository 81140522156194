import React, { useState, useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Col,
} from "shards-react";
import axios from 'axios';
import { showToast } from '../../helpers/toast';
import { cookies } from '../../helpers/createCookies';
import styled from 'styled-components';

const ClientesData = ({props}) => {
    const [clientes, setClientes] = useState([]);
    const [empresasTrabajo, setEmpresasTrabajo] = useState([]);
    useEffect(() => {
        getClientes();
        getEmpresasTrabajo();
    }, []);
    const getClientes = () => {
        axios.get(`/getClientes?token=${cookies.get('token')}`).then(({ data }) => {
            setClientes(data.data);
        });
    }
    const getEmpresasTrabajo = () => {
        axios.get(`/getEmpresasTrabajo?token=${cookies.get('token')}`).then(({ data }) => {
            setEmpresasTrabajo(data);
        });
    }
    const verFicha = (e) => {
        props.history.push(`/ficha-cliente/${e.target.parentNode.parentNode.parentNode.getAttribute('id')}`);
    }
    const eliminarCliente = (e) => {
        e.preventDefault();
        // El token hay que cogerlo de las cookies
        const datos = {
            token: cookies.get('token'),
            id: e.target.parentNode.parentNode.parentNode.getAttribute('id'),
        }
        axios.post('/eliminarCliente', datos).then((data) => {
            showToast('ok', '¡Cliente eliminado correctamente!');
            getClientes();
            getEmpresasTrabajo();
        }).catch((err) => {
            showToast('err', err.response.statusText);
        });
    }
    return (
        <Container>
            <ContainerEmpresas>
                {
                    empresasTrabajo && empresasTrabajo.map(({ nombre }, index) => <CardEmpresa key={index}><h4>{nombre}</h4></CardEmpresa>)
                }
            </ContainerEmpresas>
            <ContainerClientes>
                {
                    clientes.map(({id, nombre, empresa}, index) => {
                        return (
                            <Col sm='3' key={index} id={id}>
                                <Card small className="mb-4" >
                                    <CardHeader className="border-bottom">
                                        <h6 className="m-0">{nombre}</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <p>Empresa: <strong>{empresa}</strong></p>
                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={verFicha}>
                                            Ver ficha
                                        </Button>
                                        <Button size="sm" theme="danger" className="mb-2 mr-1" onClick={eliminarCliente}>
                                            Eliminar cliente
                                        </Button>    
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                }
            </ContainerClientes>
        </Container>
        
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
`;

const ContainerEmpresas = styled.div`
    display: inherit;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10em;
    margin-bottom: 5em;
`;

const ContainerClientes = styled.div`
    display: inherit;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;
`;

const CardEmpresa = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 15em;
    height: 10em;
    border: 1px solid black;
    border-radius: 15px;
`;

export default ClientesData;
