import React, { useState, useEffect } from "react";
import Calendar from "react-awesome-calendar";
import axios from "axios";
import styled from "styled-components";
import { cookies } from "../../helpers/createCookies";
import {
  Button,
  FormInput,
  FormSelect,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "shards-react";
import { showToast } from "../../helpers/toast";

const Calendario = () => {
  const [events, setEvents] = useState([]);
  const [tipoAusencia, setTipoAusencia] = useState(3);
  const [fechaInicio, setFechaInicio] = useState(0);
  const [fechaFinal, setFechaFinal] = useState(0);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    const source = axios.CancelToken.source();
    getEvents(source);
    return () => {
      source.cancel();
    };
  }, []);
  const getEvents = (source) => {
    axios
      .get(`/getEventos?token=${cookies.get("token")}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        setEvents(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const setEvent = (e) => {
    e.preventDefault();
    axios
      .post("/setEvento", {
        token: cookies.get("token"),
        tipo: tipoAusencia,
        fecha_inicio: fechaInicio,
        fecha_final: fechaFinal,
      })
      .then(({ data }) => {
          showToast('ok', 'Ausencia pedida correctamente');
        getEvents(axios.CancelToken.source());
        setModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOnChangeInicio = (e) => {
      setFechaInicio(new Date(e.target.value).getTime())
  }
  const handleOnChangeFinal = (e) => {
      setFechaFinal(new Date(e.target.value).getTime())
  }
  return (
    <>
      <Calendar 
        events={events}
      />
      <Button className='mt-3' onClick={() => setModal(true)} theme='success' outline>Pedir ausencia</Button>
      <Modal open={modal} toggle={() => setModal(false)}>
        <ModalHeader>Pedir ausencia</ModalHeader>
        <ModalBody>
          <FormSelect
            onChange={(e) => setTipoAusencia(e.target.value)}
            value={tipoAusencia}
          >
            <option value="3">Vacaciones</option>
            <option value="4">Ausencia</option>
            <option value="2">Baja</option>
          </FormSelect>
          <InputGroup className="mt-3">
            <label className="mr-3" for="fecha_inicio">
              Fecha inicio
              <FormInput id="fecha_inicio" type="date" onChange={handleOnChangeInicio} />
            </label>
            <label for="fecha_final" onChange={handleOnChangeFinal} >
              Fecha final
              <FormInput id="fecha_final" type="date" />
            </label>
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button theme="danger" outline>
            Cancelar
          </Button>
          <Button outline onClick={setEvent}>Guardar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Calendario;
