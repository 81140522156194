// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
    return [
      {
          title: "Panel de control",
          to: "/blog-overview",
          htmlBefore: '<i class="material-icons">edit</i>',
      },
      {
        title: "Clientes",
        htmlBefore: '<i class="material-icons">person</i>',
        to: "/blog-posts",
      },
      {
        title: "Nuevo cliente",
        htmlBefore: '<i class="material-icons">person_add</i>',
        to: "/add-new-post",
      },
    ];
  }
  