// eslint-disable-next-line import/no-anonymous-default-export
export default function() {
    return [
        {
            title: "Panel de control",
            to: "/blog-overview",
            htmlBefore: '<i class="material-icons">edit</i>',
            type_user_allowed: 0,
        },
        {
            title: "Inbox",
            to: "/ordenes-de-trabajo",
            htmlBefore: '<i class="material-icons">edit</i>',
            type_user_allowed: 1,
        },
        {
            title: "Asignadas",
            htmlBefore: '<i class="material-icons">person</i>',
            to: "/ordenes-asignadas",
            type_user_allowed: 0,
        },
        {
            title: "Pendientes de validar",
            htmlBefore: '<i class="material-icons">error</i>',
            to: "/ordenes-pendientes-de-validar",
            type_user_allowed: 1,
        },
        {
            title: 'Cerradas',
            htmlBefore: '<i class="material-icons">table_chart</i>',
            to: '/ordenes-cerradas',
            type_user_allowed: 1,
        },
    ];
  }
  