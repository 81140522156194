import React, { useEffect } from 'react';
import { Button, ModalBody, ModalHeader, Row, Col } from "shards-react";
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateEstado, estado } from '../../reducers/tareaSeleccionadaReducer';
import "../../assets/calendar-data.css";

const ModalData = ({ data }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateEstado(data.finalizado === 1 ? false : true));
    }, [data.finalizado, dispatch]);
    const checkEstado = useSelector(estado);
    return (
        <>
            <ModalHeader>{data.nombre}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <p><b>Asunto:</b> {data.asunto}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p><b>Mensaje</b>: {data.mensaje}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p><b>Estado: </b>
                            {
                                checkEstado ? 
                                <Button outline theme='success' onClick={() => dispatch(updateEstado(false))}>Finalizada</Button>
                                :
                                <Button outline theme='warning' onClick={() => dispatch(updateEstado(true))}>Pendiente</Button>
                            }
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button outline theme='info'>Descargar archivos</Button>
                    </Col>
                </Row>
            </ModalBody>
        </>
    )
}

export default ModalData
