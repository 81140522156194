import React, { useState } from 'react';
import '../../assets/login.css'
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { cookies } from '../../helpers/createCookies';
import { showToast } from '../../helpers/toast';

const LoginComponent = ({ props }) => {
    const [userData, setUserData] = useState([{
        email: '',
        password: '',
    }]);
    if(cookies.get('token')) return <Redirect to='/' />
    const handleOnChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value,
        });
    };
    const logIn = async (e) => {
        e.preventDefault();
        axios.post('/iniciar-sesion', userData).then(({ data }) => {
            if(!data.user){
                showToast('err', 'Email incorrecto.');
                setUserData({
                    ...userData,
                    email: '',
                });
                return;
            }
            if(!data.password) {
                showToast('err', 'Contraseña incorrecta.');
                setUserData({
                    ...userData,
                    password: '',
                });
                return;
            }
            cookies.set('token', data.token);
            cookies.set('tipo_usuario', data.tipo_trabajador);
            cookies.set('nombre', data.nombre);
            cookies.set('apellidos', data.apellidos);
            cookies.set('mail', data.mail);
            if(data.first_login === 2) {
                props.history.push('/cambiar-password');
                return;
            }
            cookies.set('first_login', '1');
            setTimeout(function () {
                props.history.push('/');
            }, 1500);
        }).catch((err) => {
            console.log(err);
            showToast('err', 'Error, inténtalo de nuevo en unos momentos. Si el error persiste, contacta con el administrador.');
        })
    }
    return (
        <div className='loginForm'>
            <h1 className='h1'>Iniciar sesión</h1>
            <form className='form ml-4'>
                <div className='rowLogin'>
                    <label htmlFor='email' className='label'>Email</label>
                    <input type='email' name='email' autoComplete='off' placeholder='email@example.com' className='input' value={userData.email} onChange={handleOnChange} />
                </div>
                <div className='rowLogin'>
                    <label htmlFor='password' className='label'>Contraseña</label>
                    <input type='password' name='password' autoComplete='current-password' className='input' value={userData.password} onChange={handleOnChange} />
                </div>
                <button type='submit' className='btnLogin' onClick={logIn}>Iniciar sesión</button>
            </form>
        </div>
    );
};

export default LoginComponent;
