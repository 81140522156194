import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import axios from "axios";
import styled from "styled-components";
import { showToast } from "../../helpers/toast";
import { cookies } from "../../helpers/createCookies";
import { useSelector } from "react-redux";
import { id } from "../../reducers/empresaTrabajoReducer";

const BETable = ({ props }) => {
    const [clientesPrincipales, setClientesPrincipales] = useState([]);
    const [ots, setOts] = useState([]);
    useEffect(() => {
        const source = axios.CancelToken.source();
        axios.get(`/getClientesPrincipales?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
            setClientesPrincipales(data.data);
            setOts(data.ots);
        })
        .catch((err) => {
            console.log(err);
        });
        return () => {
            source.cancel();
        };
    }, []);
    const verOTs = (e) => {
        props.history.push(`/ordenes-de-trabajo/${e.target.parentNode.parentNode.getAttribute('id')}`);
    }
    const eliminarClientePrincipal = (e) => {
        e.preventDefault();
        // El token hay que cogerlo de las cookies
        const datos = {
            token: cookies.get('token'),
            id: e.target.parentNode.parentNode.getAttribute('id'),
        }
        axios.post('/eliminarClientePrincipal', datos).then((data) => {
            showToast('ok', '¡Trabajador eliminado correctamente!');
        }).catch((err) => {
            showToast('err', err.response.statusText);
        });
    }
    return (
        <Row>
            <Col>
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Clientes Princiales</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                    <table className="table mb-0">
                            <thead className="bg-light">
                                <tr>
                                    <th scope="col" className="border-0">
                                        #
                                    </th>
                                    <th scope="col" className="border-0">
                                        Nombre
                                    </th>
                                    <th scope="col" className="border-0">
                                        OTs pendientes
                                    </th>
                                    <th scope="col" className="border-0">
                                        OTs finalizadas
                                    </th>
                                    <th scope="col" className="border-0">
                                        OTs totales
                                    </th>
                                    <th scope="col" className="border-0">
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    clientesPrincipales.map(({id, nombre}, index) => {
                                        const clienteOTs = ots.filter(i => i.cliente === id);
                                        const otsPendientes = clienteOTs.filter(i => i.finalizado === 1).length;
                                        const otsFinalizadas = clienteOTs.filter(i => i.finalizado === 2).length;
                                        return (
                                            <tr key={index} id={id}>
                                                <td>{index+1}</td>
                                                <td>{nombre}</td>
                                                <td>{otsPendientes}</td>
                                                <td>{otsFinalizadas}</td>
                                                <td>{otsPendientes+otsFinalizadas}</td>
                                                <td>
                                                    <Icon className='material-icons' name='visibility' onClick={verOTs}>visibility</Icon>
                                                    <Icon className='material-icons' name='delete' onClick={eliminarClientePrincipal}>delete</Icon>
                                                </td>
                                            </tr>
                                        )  
                                    })
                                }
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const Icon = styled.i.attrs((props) => ({
    className: props.className || 'material-icons',
}))`
    display: inline;
    margin-right: 1vw;
    cursor: pointer;
    transition: all .2s ease-in-out;
    color: white;
    ${({ name }) => name === 'visibility' &&`
        border: 1px solid transparent;
        border-radius: 5px;
        padding: .7em;
        background: green;
        &:hover {
            border: 1px solid green;
            background: transparent;
            color: black;
        }
    `}
    ${({ name }) => name === 'delete' &&`
        border: 1px solid transparent;
        border-radius: 5px;
        padding: .7em;
        background: red;
        &:hover {
            border: 1px solid red;
            background: transparent;
            color: black;
        }
    `}
`;

export default BETable;
