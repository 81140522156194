import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Modal, ModalFooter, Button } from 'shards-react';
import axios from 'axios';
import { cookies } from '../../helpers/createCookies';
import styled from 'styled-components';
import ModalData from './ModalData';
import { showToast } from '../../helpers/toast';
import { estado } from '../../reducers/tareaSeleccionadaReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setDataOt } from '../../reducers/otsReducer';

const TareasData = ({ props }) => {
    const [tareas, setTareas] = useState([]);
    const [tareaSeleccionada, setTareaSeleccionada] = useState([]);
    const [modal, setModal] = useState(false);
    const nuevoEstado = useSelector(estado);
    const dispatch = useDispatch();
    useEffect(() => {
        const source = axios.CancelToken.source();
        getTareas(source);
        return () => {
            source.cancel();
        };
    }, []);
    const getTareas = (source) => {
        axios.get(`/getTareas?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
            setTareas(data);
        }).catch((err) => {
            console.log(err);
        });
    }
    const verTarea = (e, data) => {
        data = { ...data };
        dispatch(setDataOt(data));
        props.history.push(`/tarea/${data.id}`)
        setTareaSeleccionada(data);
    };
    const closeModal = () => {
        setModal(false);
    };
    const saveData = (e) => {
        e.preventDefault();
        const datos = {
            token: cookies.get('token'),
            id_ot: tareaSeleccionada.id_ot,
            finalizado: nuevoEstado,
        };
        axios.post('/updateEstadoOT', datos).then((data) => {
            if(data.error) {
                showToast('err', 'Error al cambiar el estado de la tarea.');
                return;
            }
            const source = axios.CancelToken.source();
            getTareas(source);
            showToast('ok', 'Estado actualizado correctamente.');
            closeModal();
        });
    };
    return (
        <Row>
            <Col>
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Tareas</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <table className="table mb-0">
                            <thead className="bg-light">
                                <tr>
                                    <th scope="col" className="border-0">
                                        #
                                    </th>
                                    <th scope="col" className="border-0">
                                        Cliente
                                    </th>
                                    <th scope="col" className="border-0">
                                        Nombre
                                    </th>
                                    <th scope='col' className='border-0'>
                                        Asunto
                                    </th>
                                    <th scope="col" className="border-0">
                                        Fecha
                                    </th>
                                    <th scope="col" className="border-0">
                                        Estado
                                    </th>
                                    <th scope="col" className="border-0">
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tareas.map(({id, id_ot, nombre_cliente, nombre, asunto, mensaje, fecha, finalizado, cliente:id_cliente, comentario_administracion, comentario_tecnico, direccion, cp, provincia }, index) => {
                                        const data = { id, id_ot, nombre_cliente, nombre, asunto, mensaje, fecha, finalizado, id_cliente, comentario_administracion, comentario_tecnico, direccion, cp, provincia };
                                        return(
                                            <tr key={index} id={id}>
                                                <td>{index+1}</td>
                                                <td>{nombre_cliente}</td>
                                                <td>{nombre}</td>
                                                <td>{asunto}</td>
                                                <td>{fecha}</td>
                                                <td>{finalizado === 1 ? 'Pendiente' : 'Finalizada'}</td>
                                                <td>
                                                    <Icon className='material-icons' name='visibility' onClick={(e) => verTarea(e, data)}>visibility</Icon>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
                <Modal open={modal} toggle={closeModal}>
                    <ModalData data={tareaSeleccionada} />
                    <ModalFooter>
                        <Button outline theme='danger' onClick={closeModal}>Cancelar</Button>
                        <Button outline onClick={saveData}>Guardar</Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>
    )
}

const Icon = styled.i.attrs((props) => ({
    className: props.className || 'material-icons',
}))`
    display: inline;
    margin-right: 1vw;
    cursor: pointer;
    transition: all .2s ease-in-out;
    color: white;
    ${({ name }) => name === 'visibility' &&`
        border: 1px solid transparent;
        border-radius: 5px;
        padding: .7em;
        background: green;
        &:hover {
            border: 1px solid green;
            background: transparent;
            color: black;
        }
    `}
    ${({ name }) => name === 'delete' &&`
        border: 1px solid transparent;
        border-radius: 5px;
        padding: .7em;
        background: red;
        &:hover {
            border: 1px solid red;
            background: transparent;
            color: black;
        }
    `}
`;

export default TareasData;
