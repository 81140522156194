import React, { useState, useEffect } from 'react';
import {
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormInput,
    Button,
    FormSelect,
} from 'shards-react';
import axios from 'axios';
import { showToast } from '../../helpers/toast';
import { cookies } from '../../helpers/createCookies';

const FichaClienteData = ({props}) => {
    const [infoCliente, setInfoCliente] = useState([]);
    const [idCliente, setIdCliente] = useState(0);
    const [tiposCliente, setTiposCliente] = useState([]);
    useEffect(() => {
        const source = axios.CancelToken.source();
        const id = props.match.params.id;
        setIdCliente(id);
        axios.get(`/getCliente?token=${cookies.get('token')}&id=${id}`, { cancelToken: source.token }).then(({data}) => {
            setInfoCliente(data.data);
        }).catch((err) => {
            console.log(err);
        });
        axios.get(`/tipoClientes?token=${cookies.get('token')}`, { cancelToken: source.token }).then((data) => {
            setTiposCliente(data.data);
        }).catch((err) => {
            console.log(err);
        });
        return () => {
            source.cancel();
        }
    }, [props.match.params.id]);
    const handleOnChange = (e) => {
        e.preventDefault();
        setInfoCliente({
            ...infoCliente,
            [e.target.name]: e.target.value,
        })
    }
    const actualizarDatosCliente = (e) => {
        e.preventDefault();
        axios.post('/updateCliente', infoCliente).then((data) => {
            showToast('ok', '¡Datos actualizados correctamente!');
        }).catch((err) => {
            showToast('err', err.response.statusText);
        });
    }
    const eliminarCliente = (e) => {
        e.preventDefault();
        // El token hay que cogerlo de las cookies
        const datos = {
            token: cookies.get('token'),
            id: idCliente,
        }
        axios.post('/eliminarCliente', datos).then((data) => {
            showToast('ok', '¡Cliente eliminado correctamente!');
            props.history.push('/blog-posts');
        }).catch((err) => {
            showToast('err', err.response.statusText);
        });
    }
    return (
        <ListGroup flush>
        <ListGroupItem className='p-3'>
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col md='6' className='form-group'>
                    <label htmlFor='nombre'>Nombre</label>
                    <FormInput
                        id='nombre'
                        name='nombre'
                        type='text'
                        placeholder='Nombre'
                        value={infoCliente.nombre}
                        onChange={handleOnChange}
                    />
                  </Col>
                  <Col md='6'>
                    <label htmlFor='apellidos'>Apellidos</label>
                    <FormInput
                      id='apellidos'
                      name='apellidos'
                      type='text'
                      placeholder='Apellidos'
                      value={infoCliente.apellidos}
                      onChange={handleOnChange}
                    />
                  </Col>
                </Row>
                <Row form>
                    <Col md='4'>
                        <label htmlFor='empresa'>Empresa</label>
                        <FormInput
                            id='empresa'
                            name='empresa'
                            type='text'
                            placeholder='Empresa'
                            value={infoCliente.empresa}
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col>
                        <label htmlFor='nif'>NIF</label>
                        <FormInput
                            id='nif'
                            name='nif'
                            type='text'
                            placeholder='NIF'
                            value={infoCliente.nif}
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col>
                    <label htmlFor='tipo_cliente'>Tipo cliente</label>
                        <FormSelect
                            id='tipo_cliente'
                            name='tipo_cliente'
                            type='text'
                            placeholder='Tipo cliente'
                            value={infoCliente.tipo_cliente}
                            onChange={handleOnChange}
                        >
                            {
                                tiposCliente.map(({id, tipo_cliente}, index) => (
                                    <option key={index} id={id} value={id}>{tipo_cliente}</option>
                                ))
                            }
                        </FormSelect>
                    </Col>
                </Row>
                <br />
                <Row form>
                    <Col>
                        <label htmlFor='telefono'>Teléfono</label>
                        <FormInput
                            id='telefono'
                            name='telefono'
                            type='number'
                            placeholder='Teléfono'
                            value={infoCliente.telefono}
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col>
                        <label htmlFor='mail'>Email</label>
                        <FormInput
                            id='mail'
                            name='mail'
                            type='mail'
                            placeholder='Email'
                            value={infoCliente.mail}
                            onChange={handleOnChange}
                        />
                    </Col>
                </Row>
                <br />
                <Button type='submit' theme='primary' className="mb-2 mr-1" onClick={actualizarDatosCliente}>Actualizar datos</Button>
                <Button type='submit' theme='danger' className="mb-2 mr-1" onClick={eliminarCliente}>Eliminar cliente</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    )
}

export default FichaClienteData;
