import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, LoginLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import FichaCliente from "./views/FichaCliente";
import Trabajadores from "./views/Trabajadores";
import AddNewTrabajador from "./views/AddNewTrabajador";
import FichaTrabajadorView from "./views/FichaTrabajadorView";
import OTs from "./views/OTs";
import OTsCliente from "./views/OTsCliente";
import OTsClienteCheck from "./views/OTsClienteCheck";
import Login from "./views/Login";
import Tareas from "./views/Tareas";
import CambiarPasswd from "./views/CambiarPasswd";
import EmpresasTrabajo from "./views/EmpresasTrabajo";
import OTView from "./views/OTView";
import TareaView from "./views/TareaView";
import Logistica from "./views/LogisticaComponent";
import NuevoVehiculo from "./views/NuevoVehiculo";
import DocumentosView from "./views/DocumentosView";
import OTsAsignadas from "./views/OTsAsignadas";
import OTsPendientes from "./views/OTsPendientes";
import OTsCerradas from "./views/OTsCerradas";
import GestionAusenciasView from "./views/GestionAusenciasView";
import SedesView from "./views/SedesView";
import CalendarioView from "./views/CalendarioView";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/blog-overview" />,
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview,
    noNavbar: true,
    noFooter: true,
    noMainSidebar: true,
    login: true,
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite,
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost,
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors,
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview,
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables,
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts,
  },
  {
    path: '/ficha-cliente/:id',
    layout: DefaultLayout,
    component: FichaCliente,
  },
  {
    path: '/trabajadores',
    layout: DefaultLayout,
    component: Trabajadores,
  },
  {
    path: '/nuevo-trabajador',
    layout: DefaultLayout,
    component: AddNewTrabajador,
  },
  {
    path: '/ficha-trabajador/:id',
    layout: DefaultLayout,
    component: FichaTrabajadorView,
  },
  {
    path: '/ordenes-de-trabajo/:id?',
    layout: DefaultLayout,
    component: OTsClienteCheck,
  },
  {
    path: '/iniciar-sesion',
    layout: DefaultLayout,
    component: Login,
    noNavbar: true,
    noFooter: true,
    noMainSidebar: true,
    login: true,
  },
  {
    path: '/tareas',
    layout: DefaultLayout,
    component: Tareas,
  },
  {
    path: '/cambiar-password',
    layout: DefaultLayout,
    component: CambiarPasswd,
    noNavbar: true,
    noFooter: true,
    noMainSidebar: true,
    login: true,
  },
  {
    path: '/empresas-trabajo',
    layout: DefaultLayout,
    component: EmpresasTrabajo,
  },
  {
    path: '/orden-de-trabajo/:id',
    layout: DefaultLayout,
    component: OTView,
  },
  {
    path: '/tarea/:id',
    layout: DefaultLayout,
    component: TareaView,
  },
  {
    path: '/vehiculos',
    layout: DefaultLayout,
    component: Logistica,
  },
  {
    path: '/nuevo-vehiculo',
    layout: DefaultLayout,
    component: NuevoVehiculo,
  },
  {
    path: '/documentos',
    layout: DefaultLayout,
    component: DocumentosView,
  },
  {
    path: '/ordenes-asignadas',
    layout: DefaultLayout,
    component: Tareas,
  },
  {
    path: '/ordenes-pendientes-de-validar',
    layout: DefaultLayout,
    component: OTsPendientes,
  },
  {
    path: '/ordenes-cerradas',
    layout: DefaultLayout,
    component: OTsCerradas,
  },
  {
    path: '/ausencias',
    layout: DefaultLayout,
    component: GestionAusenciasView,
  },
  {
    path: '/sedes',
    layout: DefaultLayout,
    component: SedesView,
  },
  {
    path: '/calendario',
    layout: DefaultLayout,
    component: CalendarioView,
  }
];
