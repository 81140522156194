import axios from 'axios';
import React, { useState } from 'react'
import { cookies } from '../../helpers/createCookies';
import { showToast } from '../../helpers/toast';

const CambiarPasswdData = ({ props }) => {
    if(cookies.get('first_login') === '1') props.history.push('/');
    const [passwds, setPasswds] = useState({
        token: cookies.get('token'),
        passwd: '',
        confirmPasswd: '',
    });
    const handleOnChange = (e) => {
        setPasswds({
            ...passwds,
            [e.target.name]: e.target.value,
        });
    }
    const changePasswd = (e) => {
        e.preventDefault();
        if(passwds.passwd !== passwds.confirmPasswd) {
            showToast('warn', 'Las contraseñas no coinciden.');
            return;
        }
        axios.post('/cambiarPassword', passwds).then((data) => {
            if(data.error) {
                showToast('err', 'Error al actualizar la contraseña.');
                return;
            }
            props.history.push('/');
        }).catch((err) => {
            console.log(err);
            showToast('err', 'Error, si el error persiste contacta con el administrador.');
        })
    }
    return (
        <div className='loginForm'>
            <h1 className='h1'>Cambiar la contraseña</h1>
            <form className='form ml-4'>
                <div className='rowLogin'>
                    <label htmlFor='email' className='label'>Contraseña</label>
                    <input type='password' name='passwd' autoComplete='off' className='input' value={passwds.passwd} onChange={handleOnChange} />
                </div>
                <div className='rowLogin'>
                    <label htmlFor='password' className='label'>Confirma contraseña</label>
                    <input type='password' name='confirmPasswd' autoComplete='current-password' className='input' value={passwds.confirmPasswd} onChange={handleOnChange} />
                </div>
                <button type='submit' className='btnLogin' onClick={changePasswd}>Cambiar contraseña</button>
            </form>
        </div>
    )
}

export default CambiarPasswdData;
