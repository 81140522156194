import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import Avatar from './../../../../images/avatars/0.jpg'
import { cookies } from '../../../../helpers/createCookies';

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  
  render() {
    const signOut = () => {
      console.log('Cierro sesion');
      cookies.remove('tipo_usuario');
      cookies.remove('token');
      cookies.remove('first_login');
      cookies.remove('nombre');
      cookies.remove('apellidos');
    }
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          {/*<img
            className="user-avatar rounded-circle mr-2"
            src='https://media-exp1.licdn.com/dms/image/C4D03AQFM_j0FDeAx1w/profile-displayphoto-shrink_800_800/0/1530520563682?e=1639612800&v=beta&t=XD6OfT3nCtuSShVikWMRL8nfFHGQQjwMCAIcgwgiy-Y'
            alt="User Avatar"
          />*/}{" "}
          <span className="d-none d-md-inline-block">{cookies.get('nombre')} {cookies.get('apellidos')}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="user-profile">
            <i className="material-icons">&#xE7FD;</i> Perfil
          </DropdownItem>
          <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Editar perfil
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Archivos
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transacciones
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/" className="text-danger" onClick={signOut}>
            <i className="material-icons text-danger">&#xE879;</i> Cerrar sesión
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
