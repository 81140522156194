import React, { useState, useEffect } from 'react';
import {
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormInput,
    Button,
    FormSelect,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'shards-react';
import axios from 'axios';
import { showToast } from '../../helpers/toast';
import { cookies } from '../../helpers/createCookies';
import styled from 'styled-components';
import Upload from '../../assets/upload.svg';

const FichaTrabajadorData = ({props}) => {
    const [infoTrabajador, setInfoTrabajador] = useState({
        nombre: '',
        apellidos: '',
        telefono: '',
        mail: '',
        dni: '',
        tipo_trabajador: '',
    });
    const [idTrabajador, setIdTrabajador] = useState(0);
    const [tiposTrabajador, setTiposTrabajador] = useState([{ 1: 0 }]);
    const [modalAddArchivos, setModalAddArchivos] = useState(false);
    const [selectedArchivos, setSelectedArchivos] = useState({ files: [] });
    useEffect(() => {
        const id = props.match.params.id;
        setIdTrabajador(id);
        axios.get(`/getTrabajador?token=${cookies.get('token')}&id=${id}`).then(({ data }) => {
            setInfoTrabajador(data.data);
        });
        axios.get(`/tipoTrabajadores?token=${cookies.get('token')}`).then(({ data }) => {
            setTiposTrabajador(data);
        });
    }, [props.match.params.id]);
    const handleOnChange = (e) => {
        e.preventDefault();
        setInfoTrabajador({
            ...infoTrabajador,
            [e.target.name]: e.target.value,
            token: cookies.get('token'),
        })
    }
    const actualizarDatosTrabajador = (e) => {
        e.preventDefault();
        axios.post('/updateTrabajador', infoTrabajador).then((data) => {
            showToast('ok', '¡Datos actualizados correctamente!');
        }).catch((err) => {
            showToast('err', err.response.statusText);
        });
    }
    const eliminarTrabajador = (e) => {
        e.preventDefault();
        // El token hay que cogerlo de las cookies
        const datos = {
            token: cookies.get('token'),
            id: idTrabajador,
        }
        axios.post('/eliminarTrabajador', datos).then((data) => {
            showToast('ok', 'Trabajador eliminado correctamente!');
            props.history.push('/trabajadores');
        }).catch((err) => {
            showToast('err', err.response.statusText);
        });
    }
    const closeModalAddArchivos = (e) => {
        e.preventDefault();
        setModalAddArchivos(false);
    }
    const saveArchivos = (e) => {
        e.preventDefault();
        setModalAddArchivos(false);
    }
    const addArchivos = (e) => {
        let formData = new FormData();
        for(let i = 0; i < e.target.files.length; i++) {
            formData.append('selectedArchivos', e.target.files[i], `${cookies.get('token')} ${e.target.files[i].name}`);
        }
        formData.append('text', JSON.stringify({ 
            token: cookies.get('token'),
            trabajador: idTrabajador,
        }))
        setSelectedArchivos({ files: e.target.files });
        axios.post('/uploadArchivos', formData).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }
    const downloadArchivos = (e) => {
        e.preventDefault();
        axios.get(`/downloadArchivos?token=${cookies.get('token')}&trabajador=${idTrabajador}`).then(data => {
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');
            link.href = url;
            link.className = 'descarga_archivo';
            link.setAttribute('download', `${idTrabajador}.zip`);
            document.body.appendChild(link);
            link.click();
            document.getElementsByClassName('descarga_archivo')[0].remove();
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <ListGroup flush>
        <ListGroupItem className='p-3'>
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col md='6' className='form-group'>
                    <label htmlFor='nombre'>Nombre</label>
                    <FormInput
                        id='nombre'
                        name='nombre'
                        type='text'
                        placeholder='Nombre'
                        value={infoTrabajador.nombre}
                        onChange={handleOnChange}
                    />
                  </Col>
                  <Col md='6'>
                    <label htmlFor='apellidos'>Apellidos</label>
                    <FormInput
                      id='apellidos'
                      name='apellidos'
                      type='text'
                      placeholder='Apellidos'
                      value={infoTrabajador.apellidos}
                      onChange={handleOnChange}
                    />
                  </Col>
                </Row>
                <Row form>
                    <Col>
                        <label htmlFor='dni'>DNI</label>
                        <FormInput
                            id='dni'
                            name='dni'
                            type='text'
                            placeholder='DNI'
                            value={infoTrabajador.dni}
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col>
                    <label htmlFor='tipo_trabajador'>Tipo trabajador</label>
                        <FormSelect
                            id='tipo_trabajador'
                            name='tipo_trabajador'
                            type='text'
                            placeholder='Tipo cliente'
                            value={infoTrabajador.tipo_trabajador}
                            onChange={handleOnChange}
                        >
                            {
                                tiposTrabajador.map(({id, tipo_trabajador}, index) => (
                                    <option key={index} id={id} value={id}>{tipo_trabajador}</option>
                                ))
                            }
                        </FormSelect>
                    </Col>
                </Row>
                <br />
                <Row form>
                    <Col>
                        <label htmlFor='telefono'>Teléfono</label>
                        <FormInput
                            id='telefono'
                            name='telefono'
                            type='number'
                            placeholder='Teléfono'
                            value={infoTrabajador.telefono}
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col>
                        <label htmlFor='mail'>Email</label>
                        <FormInput
                            id='mail'
                            name='mail'
                            type='mail'
                            placeholder='Email'
                            value={infoTrabajador.mail}
                            onChange={handleOnChange}
                        />
                    </Col>
                </Row>
                <br />
                <Button type='submit' theme='primary' className="mb-2 mr-1" onClick={actualizarDatosTrabajador}>Actualizar datos</Button>
                <Button type='submit' theme='danger' className="mb-2 mr-1" onClick={eliminarTrabajador}>Eliminar trabajador</Button>
                <Button type='button' theme='info' className="mb-2 mr-1" onClick={downloadArchivos}>Descargar archivos</Button>
                <Button type='button' theme='success' className="mb-2 mr-1" onClick={() => setModalAddArchivos(true)}>Subir archivos</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
        <Modal open={modalAddArchivos} toggle={closeModalAddArchivos} size='lg'>
                <ModalHeader>Añadir nuevos archivos</ModalHeader>
                <ModalBody>
                    <Form>
                        <LabelFile htmlFor='frontal'><FileIcon src={Upload} />&nbsp;Archivos<InputFile id='frontal' type='file' multiple onChange={addArchivos} /></LabelFile>
                        
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline theme='danger' onClick={closeModalAddArchivos}>Cancelar</Button>
                    <Button outline onClick={saveArchivos}>Guardar</Button>
                </ModalFooter>
            </Modal>
      </ListGroup>
    )
}

const InputFile = styled.input`
    width: 100%;
    height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;

const LabelFile = styled.label`
    font-size: 2rem;
    font-weight: 700;
    color: white;
    background-color: rgba(100, 149, 237, 0.5);
    display: inline-block;
    padding: 1em;
    border-radius: 5px;
    border: 3px solid cornflowerblue;
    color: #417DC1;
    margin-right: 5px;
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FileIcon = styled.img`
    width: 2em;
    height: 2em;
    display: inline-block;
    margin-right: 5px;
`;

export default FichaTrabajadorData;
