import { createSlice } from '@reduxjs/toolkit'

export const otsReducer = createSlice({
  name: 'ots',
  initialState: {
    trabajador: null,
    equipo: null,
    idOt: null,
    fecha: null,
    hora: {
      hora: null,
      minutos: null,
    },
    dataOt: {},
  },
  reducers: {
    updateTrabajador: (state, action) => {
      state.trabajador = action.payload !== 'Ninguno' ? action.payload : null;
    },
    updateEquipo: (state, action) => {
      state.equipo = action.payload !== 'Ninguno' ? action.payload : null;
    },
    updateIdOt: (state, action) => {
      state.idOt = action.payload;
    },
    updateFecha: (state, action) => {
      state.fecha = action.payload;
    },
    updateHora: (state, action) => {
      if(action.payload.nombre === 'hora') state.hora.hora = action.payload.x;
      else if(action.payload.nombre === 'minutos') state.hora.minutos = action.payload.x;
    },
    selectReset: (state, action) => {
      state.trabajador = null;
      state.equipo = null;
    },
    setDataOt: (state, action) => {
      state.dataOt = action.payload;
    },
    reset: (state, action) => {
      state.trabajador = null;
      state.equipo = null;
      state.idOt = null;
      state.fecha = null;
      state.hora = {
        hora: null,
        minutos: null,
      };
      state.dataOt = {};
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateTrabajador, updateEquipo, updateIdOt, updateFecha, updateHora, selectReset, setDataOt, reset } = otsReducer.actions;

export const trabajador = (state) => state.ots.trabajador;
export const equipo = (state) => state.ots.equipo;
export const idOt = (state) => state.ots.idOt;
export const fecha = (state) => state.ots.fecha;
export const hora = (state) => state.ots.hora;
export const dataOt = (state) => state.ots.dataOt;

export default otsReducer.reducer;