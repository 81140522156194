import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";
import getSidebarNonAdminItems from '../data/sidebar-nav-items-non-admin';
import getSidebarTrabajosItems from '../data/sidebar-nav-items-trabajos';
import getSidebarRRHHItems from '../data/sidebar-nav-items-rrhh';
import getSidebarClientesItems from '../data/sidebar-nav-items-clientes';
import getSidebarLogisticaItems from '../data/sidebar-nav-items-logistica';
import getSidebarDocumentosItems from '../data/sidebar-nav-items-documentos';

let _store = {
  menuVisible: false,
  navItems: getSidebarNavItems(),
  nonAdminNavItems: getSidebarNonAdminItems(),
  navTrabajosItems: getSidebarTrabajosItems(),
  navRRHHItems: getSidebarRRHHItems(),
  navClientesItems: getSidebarClientesItems(),
  navLogisticaItems: getSidebarLogisticaItems(),
  navDocumentosItems: getSidebarDocumentosItems(),
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    return _store.navItems;
  }

  getSidebarNonAdminNavItems () {
    return _store.nonAdminNavItems;
  }

  getSidebarTrabajosItems() {
    return _store.navTrabajosItems;
  }
  
  getSidebarRRHHItems() {
    return _store.navRRHHItems;
  }

  getSidebarClientesItems() {
    return _store.navClientesItems;
  }

  getSidebarLogisticaItems() {
    return _store.navLogisticaItems;
  }

  getSidebarDocumentosItems() {
    return _store.navDocumentosItems;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
