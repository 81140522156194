import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import CambiarPasswdData from '../components/CambiarPasswd/CambiarPasswdData';

const CambiarPasswd = (props) => {
    return (
        <div className="row justify-content-center">
            <div className='col'>
                <CambiarPasswdData props={props} />
            </div>
            <ToastContainer
                position='bottom-center'
                hideProgressBar={false}
                autoClose={5000}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                transition={Slide}
            />
        </div>
    )
}

export default CambiarPasswd;
