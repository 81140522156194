import React from 'react';
import { Container, Row, Col } from "shards-react";
import { ToastContainer, Slide } from 'react-toastify';

import PageTitle from "../components/common/PageTitle";
import AddTrabajador from '../components/trabajadores/AddTrabajador';

const AddNewTrabajador = () => {
    return (
        <Container fluid className="main-content-container px-4 pb-4">
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Nuevo trabajador" subtitle="Añadir" className="text-sm-left" />
            </Row>
            <Row>
                <Col lg="12" md="12">
                    <AddTrabajador />
                </Col>
            </Row>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                transition={Slide}
            />
        </Container>
    )
}

export default AddNewTrabajador;
