import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { cookies } from "../../helpers/createCookies";

const GestionAusencias = ({ props }) => {
  const [activeBtn, setActiveBtn] = useState("left");
  const [ausencias, setAusencias] = useState(null);
  useEffect(() => {
    const source = axios.CancelToken.source();
    getAusencias(source);
    return () => {
      source.cancel();
    };
  }, [activeBtn]);

  const getAusencias = (source) => {
    axios
      .get(`/getAusencias?token=${cookies.get("token")}&tipo=${activeBtn}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        setAusencias(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTipoAusencia = (tipo) => {
    switch (tipo) {
      case 2:
        return "Baja";
      case 3:
        return "Vacaciones";
      case 4:
        return "Otros";
      default:
        return "Tipo desconocido";
    }
  };
  const handleOnClickActionBtn = (e, ausencia) => {
    e.preventDefault();
    axios
      .post("/updateAusencia", {
        token: cookies.get("token"),
        id: ausencia.id,
        action: ausencia.action,
      })
      .then(({ data }) => {
        getAusencias(axios.CancelToken.source());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Container>
      <BtnsContainer>
        <BtnPendientes active={activeBtn} onClick={() => setActiveBtn("left")}>
          Ausencias pendientes
        </BtnPendientes>
        <BtnValidadas active={activeBtn} onClick={() => setActiveBtn("right")}>
          Ausencias validadas
        </BtnValidadas>
      </BtnsContainer>
      {!ausencias || !ausencias.length ? (
        <span>
          No hay ausencias {activeBtn === "left" ? "pendientes" : "validadas"}
        </span>
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th>Trabajador</Th>
              <Th>Fecha inicio</Th>
              <Th>Fecha fin</Th>
              <Th>Tipo</Th>
              {activeBtn === "left" && <Th>Acciones</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {ausencias.map(
              ({ id, tipo, fecha_inicio, fecha_final, trabajador }, index) => {
                return (
                  <Tr key={index}>
                    <Td>{trabajador}</Td>
                    <Td>
                      {moment(parseInt(fecha_inicio)).format("DD-MM-YYYY")}
                    </Td>
                    <Td>
                      {moment(parseInt(fecha_final)).format("DD-MM-YYYY")}
                    </Td>
                    <Td>{getTipoAusencia(tipo)}</Td>
                    {activeBtn === "left" && (
                      <Td>
                        <ActionText
                          color="green"
                          onClick={(e) =>
                            handleOnClickActionBtn(e, { id, action: "1" })
                          }
                        >
                          Aceptar
                        </ActionText>
                        <ActionText
                          color="red"
                          onClick={(e) =>
                            handleOnClickActionBtn(e, { id, action: "2" })
                          }
                        >
                          Denegar
                        </ActionText>
                      </Td>
                    )}
                  </Tr>
                );
              }
            )}
          </Tbody>
        </Table>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BtnsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 3em;
`;

const BtnPendientes = styled.button`
  outline: none;
  border: none;
  height: 2.8rem;
  width: 15rem;
  border-radius: 15px 0 0 15px;
  color: white;
  font-size: 1.05em;
  font-weight: bold;
  background-color: rgba(100, 149, 237, 0.5);
  border: 3px solid cornflowerblue;
  ${({ active }) =>
    active === "left" &&
    `
        background-color: rgba(100, 149, 237);
    `}
`;

const BtnValidadas = styled.button`
  outline: none;
  border: none;
  height: 2.8rem;
  width: 15rem;
  border-radius: 0 15px 15px 0;
  color: white;
  font-size: 1.05em;
  font-weight: bold;
  background-color: rgba(100, 149, 237, 0.5);
  border: 3px solid cornflowerblue;
  ${({ active }) =>
    active === "right" &&
    `
        background-color: rgba(100, 149, 237);
    `}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
  margin-bottom: 1em;
  color: black;
`;

const Thead = styled.thead`
  background-color: white;
`;

const Tbody = styled.tbody`
  background-color: #fff;
  tr:nth-child(odd) {
    background-color: #c2dcff;
  }
`;

const Tr = styled.tr`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

const Th = styled.th`
  border: none;
  padding: 8px;
`;

const Td = styled.td`
  padding: 0.8em;
`;

const Btn = styled.button`
  background-color: #00a8ff;
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 0.5em;
  &:hover {
    background-color: #0097e6;
  }
`;

const ActionText = styled.span`
  color: ${({ color }) => color};
  text-decoration: underline;
  cursor: pointer;
  :first-child {
    margin-right: 0.5em;
  }
`;

export default GestionAusencias;
