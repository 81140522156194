import React, { useEffect, useState } from 'react';
import {
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormInput,
    Button,
    FormSelect,
} from 'shards-react';
import axios from 'axios';
import { showToast } from '../../helpers/toast';
import { cookies } from '../../helpers/createCookies';

// El token hay que cogerlo de las cookies
const initialState = {
    token: cookies.get('token'),
    nombre: '',
    apellidos: '',
    empresa: '',
    nif: '',
    tipo_cliente: '',
    telefono: '',
    mail: '',
};

const AddCliente = () => {
    const [datosCliente, setDatosCliente] = useState(initialState);
    const [tiposCliente, setTiposCliente] = useState([]);
    useEffect(() => {
        const source = axios.CancelToken.source();
        axios.get(`/tipoClientes?token=${cookies.get('token')}`, { cancelToken: source.token }).then((data) => {
            setTiposCliente(data.data);
        }).catch((err) => {
            console.log(err);
          });
        return () => {
            source.cancel();
        }
    }, [])
    const handleOnChange = (e) => {
        e.preventDefault();
        setDatosCliente({
            ...datosCliente,
            [e.target.name]: e.target.value,
        });
    }
    const handleOnClick = async (e) => {
        e.preventDefault();
        axios.post('/addCliente', datosCliente).then((data) => {
            showToast('ok', '¡Cliente creado correctamente!');
            setDatosCliente(initialState);
        }).catch((err) => {
            showToast('err', err.response.statusText);
        });
    }
    return (
        <ListGroup flush>
        <ListGroupItem className='p-3'>
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col md='6' className='form-group'>
                    <label htmlFor='nombre'>Nombre</label>
                    <FormInput
                        id='nombre'
                        name='nombre'
                        type='text'
                        placeholder='Nombre'
                        value={datosCliente.nombre}
                        onChange={handleOnChange}
                    />
                  </Col>
                  <Col md='6'>
                    <label htmlFor='apellidos'>Apellidos</label>
                    <FormInput
                      id='apellidos'
                      name='apellidos'
                      type='text'
                      placeholder='Apellidos'
                      value={datosCliente.apellidos}
                      onChange={handleOnChange}
                    />
                  </Col>
                </Row>
                <Row form>
                    <Col md='4'>
                        <label htmlFor='empresa'>Empresa</label>
                        <FormInput
                            id='empresa'
                            name='empresa'
                            type='text'
                            placeholder='Empresa'
                            value={datosCliente.empresa}
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col>
                        <label htmlFor='nif'>NIF</label>
                        <FormInput
                            id='nif'
                            name='nif'
                            type='text'
                            placeholder='NIF'
                            value={datosCliente.nif}
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col>
                    <label htmlFor='tipo_cliente'>Tipo cliente</label>
                        <FormSelect
                            id='tipo_cliente'
                            name='tipo_cliente'
                            type='text'
                            placeholder='Tipo cliente'
                            value={datosCliente.tipo_cliente}
                            onChange={handleOnChange}
                        >
                            {
                                tiposCliente.map(({id, tipo_cliente}, index) => (
                                    <option key={index} id={id} value={id}>{tipo_cliente}</option>
                                ))

                            }
                        </FormSelect>
                    </Col>
                </Row>
                <br />
                <Row form>
                    <Col>
                        <label htmlFor='telefono'>Teléfono</label>
                        <FormInput
                            id='telefono'
                            name='telefono'
                            type='number'
                            placeholder='Teléfono'
                            value={datosCliente.telefono}
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col>
                        <label htmlFor='mail'>Email</label>
                        <FormInput
                            id='mail'
                            name='mail'
                            type='mail'
                            placeholder='Email'
                            value={datosCliente.mail}
                            onChange={handleOnChange}
                        />
                    </Col>
                </Row>
                <br />
                <Button type='submit' onClick={handleOnClick}>Crear nuevo cliente</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    )
}

export default AddCliente;
