import React from 'react';
import { Col, Container, Row } from 'shards-react';
import { ToastContainer, Slide } from 'react-toastify';
import PageTitle from '../components/common/PageTitle';
import LoginComponent from '../components/login/LoginComponent';

const Login = (props) => {
    return (
        <div className="row justify-content-center">
            <div className='col'>
                <LoginComponent props={props} />
            </div>
            <ToastContainer
                position='bottom-center'
                hideProgressBar={false}
                autoClose={5000}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                transition={Slide}
            />
        </div>
    )
}

export default Login;
