import React, { useState, useEffect } from "react";
import { Container, Row } from "shards-react";
import { ToastContainer, Slide } from 'react-toastify';
import PageTitle from "../components/common/PageTitle";
import Vehiculos from "../components/logistica/Vehiculos";

const Logistica = ({props}) => {
    return (
        <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title='Vehículos' subtitle="Todos Los" className="text-sm-left" />
        </Row>
        <Vehiculos props={props} />
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            transition={Slide}
        />
    </Container>
    )
}

export default Logistica;
