import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { cookies } from '../../helpers/createCookies';
import { showToast } from '../../helpers/toast';

const Sedes = ({ props }) => {
    const [sedes, setSedes] = useState();
    useEffect(() => {
        const source = axios.CancelToken.source();
        axios.get(`/getSedes?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
            setSedes(data);
        }).catch((err) => {
            console.log(err);
        });
        return () => {
            source.cancel();
        };
    });

  return (
    <Container>
        {
            sedes && sedes.map(({ nombre }, index) => {
                return (
                    <Card key={index} onClick={() => showToast('warn', 'No se han encontrado sub sedes.')}>
                        <h4>{nombre}</h4>
                    </Card>
                )
            })
        }
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10em;
`;

const Card = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 25em;
    height: 15em;
    border: 1px solid black;
    border-radius: 15px;
`;

export default Sedes;