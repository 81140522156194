import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { cookies } from "../../helpers/createCookies";
import { showToast } from "../../helpers/toast";
import { FormSelect } from "shards-react";

const Vehiculos = ({ props }) => {
  const [vehiculos, setVehiculos] = useState([]);
  const [trabajadores, setTrabajadores] = useState([]);
  useEffect(() => {
    const source = axios.CancelToken.source();
    getVehiculos(source);
    axios.get(`/getTrabajadores?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
      setTrabajadores(data.data);
    }).catch((err) => {
        console.log(err);
    });
    return () => {
      source.cancel();
    };
  }, []);
  const getVehiculos = (source) => {
    axios
      .get(`/getVehiculos?token=${cookies.get("token")}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        setVehiculos(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleOnClick = (e, id) => {
    e.preventDefault();
    axios
        .post('/borrarVehiculo', { token: cookies.get('token'), id }).then(({ data }) => {
            showToast('ok', 'Vehículo borrado correctamente');
            getVehiculos(axios.CancelToken.source());
        }).catch((err) => {
            console.log(err);
            showToast('err', 'Error al borrar el vehículo');
        });
  }
  const handleOnChange = (e) => {
    axios.post('/updateTrabajadorVehiculo', { token: cookies.get('token'), idVehiculo: e.target.id, idTrabajador: e.target.value }).then(({ data }) => {
      showToast('ok', 'Trabajador asignado correctamente');
      getVehiculos(axios.CancelToken.source());
    }).catch((err) => {
      console.log(err);
      showToast('err', 'Error al asignar el trabajador');
    });
  }
  return (
    <Container>
      {vehiculos && vehiculos.length ? (
        vehiculos.map((vehiculo, index) => {
          return (
            <Card key={index}>
              <CardHeader>
                <VehicleName>{vehiculo.nombre}</VehicleName>
              </CardHeader>
              <CardBody>
                <VehicleInfo>
                  <b>Matrícula:</b> {vehiculo.matricula}
                </VehicleInfo>
                <VehicleInfo>
                  <b>Fecha de matriculación:</b> {vehiculo.fecha_matriculacion}
                </VehicleInfo>
              </CardBody>
              <CardFooter>
                <FormSelect id={vehiculo.id} value={vehiculo.trabajador_asociado} onChange={handleOnChange}>
                  <option value='0'>Selecciona un trabajador</option>
                  {
                    trabajadores.map(({ id, nombre }, index) => <option value={id} key={index}>{nombre}</option>)
                  }
                </FormSelect>
                <VehicleBtn onClick={(e) => handleOnClick(e, vehiculo.id)}>Eliminar</VehicleBtn>
              </CardFooter>
            </Card>
          );
        })
      ) : (
        <NoVehicles>No hay ningún vehículo creado</NoVehicles>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Card = styled.div`
  display: inherit;
  flex-direction: column;
  background-color: white;
  width: 25em;
  height: 20em;
  border-radius: 5px;
  padding: 1em;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  margin: 2em 1em;
`;

const CardHeader = styled.div`
  display: inherit;
  justify-content: center;
  border-bottom: 1px solid #ddd;
`;

const CardBody = styled.div`
  display: inherit;
  justify-content: flex-start;
  margin-top: 1em;
`;

const CardFooter = styled.div`
  display: inherit;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1em;
  gap: 1em;
`;

const VehicleName = styled.p`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
`;

const VehicleInfo = styled.p`
  font-size: 1.15em;
`;

const VehicleBtn = styled.button`
  background-color: transparent;
  border: 1px solid red;
  border-radius: 5px;
  color: red;
  width: 100%;
  height: 2.5em;
  transition: 0.25s all ease-in-out;
  &:hover {
    background-color: red;
    color: white;
  }
`;

const NoVehicles = styled.h3`
  text-align: center;
`;

export default Vehiculos;
