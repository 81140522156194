/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, FormInput, Row, FormSelect, Col, ModalFooter, FormTextarea, Form, FormGroup } from "shards-react";
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateEquipo, updateTrabajador, updateIdOt, updateFecha, updateHora, selectReset, dataOt } from '../../reducers/otsReducer';
import "../../assets/calendar-data.css";
import InfoDia from './InfoDia';
import { cookies } from '../../helpers/createCookies';
import { showToast } from '../../helpers/toast';
import styled from 'styled-components';
import Upload from '../../assets/upload.svg';

const OTSeleccionada = ({ props }) => {
    const data = useSelector(dataOt);
    const [trabajadores, setTrabajadores] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [trabajadorAsociado, setTrabajadorAsociado] = useState(data.trabajador_asociado);
    const [equipoAsociado, setEquipoAsociado] = useState(data.equipo_asociado);
    const [plantillas, setPlantillas] = useState([]);
    const [plantillaSeleccionada, setPlantillaSeleccionada] = useState(data.plantilla_asociada);
    const [datosPlantilla, setDatosPlantilla] = useState([]);
    const [trabajos, setTrabajos] = useState([]);
    const [preciarios, setPreciarios] = useState([]);
    const [modalTrabajos, setModalTrabajos] = useState(false);
    const [tareasPrevias, setTareasPrevias] = useState();
    const [plantillaPrevia, setPlantillaPrevia] = useState();
    const [hasItemsPreciario, setHasItemsPreciario] = useState([]);
    const [itemsPreciario, setItemsPreciario] = useState([]);
    const [modalPreciario, setModalPreciario] = useState(false);
    const [departamentos, setDepartamentos] = useState([]);
    const [comentarioPreActuacion, setComentarioPreActuacion] = useState(data.comentario_administracion);
    const [mensaje, setMensaje] = useState(data.mensaje);
    const [tareaBusqueda, setTareaBusqueda] = useState('');
    const [desplegableTareas, setDesplegableTareas] = useState(false);
    const [modalAddFotos, setModalAddFotos] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useState({ files: [] });
    const [hora, setHora] = useState({
        entrada: '',
        salida: '',
    });
    const [dataTrabajador, setDataTrabajador] = useState([]);
    const [diaSeleccionado, setDiaSeleccionado] = useState('Selecciona un dia');
    const [horaOt, setHoraOt] = useState({
        hora: '',
        minutos: '',
    });
    const [fechaOt, setFechaOt] = useState('');
    const [datosOt, setDatosOt] = useState({
        cifEmpresa: '',
        direccionEmpresa: '',
        sedeEmpresa: '',
        cliente: '',
        CIF: '',
        telefono: '',
        direccion: '',
        cp: '',
        provincia: '',
    });
    const [totalInputs, setTotalInputs] = useState([0]);
    const [comentarioTecnico, setComentarioTecnico] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateTrabajador(trabajadorAsociado));
        dispatch(updateEquipo(equipoAsociado));
        dispatch(updateIdOt(data.id));
        const source = axios.CancelToken.source();
        axios.get(`/getTrabajadores?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
            setTrabajadores(data.data);
        }).catch((err) => {
            console.log(err);
        });
        axios.get(`/getEquiposDeTrabajo?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
            setEquipos(data.data);
        }).catch((err) => {
            console.log(err);
        });
        axios.get(`/getPlantillas?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
            setPlantillas(data);
        }).catch((err) => {
            console.log(err);
        });
        axios.get(`/getDepartamentos?token=${cookies.get('token')}`, { cancelToken: source.token, }).then(({ data }) => {
            setDepartamentos(data);
        }).catch((err) => {
            console.log(err);
        });
        axios.get(`/getTareasOt?token=${cookies.get('token')}&id=${data.id}`, { cancelToken: source.token, }).then(({ data }) => {
            setTrabajos(data);
        }).catch((err) => {
            console.log(err);
        });
        axios.get(`/getPlantillaOt?token=${cookies.get('token')}&id=${data.id}`, { cancelToken: source.token, }).then(({ data }) => {
            setDatosPlantilla(data);
        }).catch((err) => {
            console.log(err);
        });
        axios.get(`/getComentarioTecnico?token=${cookies.get("token")}&id_ot=${data.id}`, { cancelToken: source.token, }).then(({ data }) => {
            setComentarioTecnico(data);
        }).catch((err) => {
            console.log(err);
        });
        getDatosPlantilla(plantillaSeleccionada);
        if(trabajadorAsociado) getEventos(trabajadorAsociado);
        if(equipoAsociado) getEventos(equipoAsociado, true);
        return () => {
            source.cancel();
        };
    }, []);
    const changeTrabajadoAsociado = (e) => {
        getEventos(e.target.value);
        dispatch(selectReset());
        dispatch(updateTrabajador(e.target.value));
        setTrabajadorAsociado(e.target.value);
        setEquipoAsociado('Ninguno');
    }
    const changeEquipoAsociado = (e) => {
        getEventos(e.target.value, true);
        dispatch(selectReset());
        dispatch(updateEquipo(e.target.value));
        setEquipoAsociado(e.target.value);
        setTrabajadorAsociado('Ninguno');
    }
    const checkDays = ({date, view}) => {
        if(view === 'month') {
            const diaActual = date.toDateString();
            const diaTrabajador = dataTrabajador.filter(o => new Date(o.fecha).toDateString() === diaActual);
            if(diaTrabajador.length > 0) {
                const tipo = diaTrabajador[0].tipo;
                const claseDia = tipo === 1 ? 'tipoTrabajo' : tipo === 2 ? 'tipoBaja' : tipo === 3 ? 'tipoVacaciones' : tipo === 4 && 'tipoAusencia'; 
                return claseDia;
            }
            return 'tipoNormal';
        }
    }
    const clickDay = (e) => {
        const diaActual = e.toDateString();
        const year = e.getFullYear();
        const month = e.getMonth().toString().length < 2 ? `0${e.getMonth()+1}` : e.getMonth()+1;
        const day = e.getDate().toString().length < 2 ? `0${e.getDate()}` : e.getDate();
        const fecha = `${year}/${month}/${day}`;
        const diaTrabajador = dataTrabajador.filter(o => new Date(o.fecha).toDateString() === diaActual);
        if(diaTrabajador.length === 0) {
            setDiaSeleccionado('No hay nada en este día.');
            setFechaOt(fecha);
            dispatch(updateFecha(fecha));
            return;
        }
        const tipo = diaTrabajador[0].tipo;
        if(tipo === 1) {
            const info = diaTrabajador.map(i => ({ nombre: i.id_ot, hora: i.hora, salida: i.salida, fecha: i.fecha, trabajador: i.trabajador, equipo: i.equipo }));
            axios.get(`/getTareasTrabajador?token=${cookies.get('token')}&trabajador=${info[0].trabajador}&equipo=${info[0].equipo}&fecha=${info[0].fecha}`).then(({ data }) => {
                setDiaSeleccionado(data);
                setFechaOt(fecha);
                dispatch(updateFecha(fecha));
            })
            return;
        }
        setFechaOt(tipo);
        const info = tipo === 2 ? 'Día de baja' : tipo === 3 ? 'Día de vacaciones' : tipo === 4 && 'Ausente';
        setDiaSeleccionado(info);
        dispatch(updateFecha(tipo));
    }
    const handleOnChangeHora = (e) => {
        const x = e.target.value.toString().length < 2 ? `0${e.target.value}` : e.target.value;
        setHoraOt({
            ...horaOt,
            [e.target.name]: x,
        });
        dispatch(updateHora({nombre: e.target.name, x: x}));
    }
    const getEventos = (id, equipo = false) => {
        const source = axios.CancelToken.source();
        if(equipo) {
            axios.get(`/getEventosEquipo?token=${cookies.get('token')}&id=${id}`, { cancelToken: source.token, }).then(({ data }) => {
                setDataTrabajador(data);
            }).catch((err) => {
                console.log(err);
                source.cancel();
            });
            return;
        }
        axios.get(`/getEventosUsuario?token=${cookies.get('token')}&id=${id}`, { cancelToken: source.token, }).then(({ data }) => {
            setDataTrabajador(data);
        }).catch((err) => {
            console.log(err);
            source.cancel();
        });
    }
    const handleOnChange = (e) => {
        setDatosOt({
            ...datosOt,
            [e.target.name]: e.target.value,
        });
    }
    const saveOt = (e) => {
        e.preventDefault();
        console.log(hora);
        if(!hora.salida) {
            showToast('warn', 'No has seleccionado la hora de salida.');
            return;
        }
        if(!hora.entrada) {
            showToast('warn', 'No has seleccionado la hora de entrada.');
            return;
        }
        if(fechaOt === 2 || fechaOt === 3 || fechaOt === 4) {
            const tipoDia = fechaOt === 2 ? 'baja' : fechaOt === 3 ? 'vacaciones' : fechaOt === 4 && 'ausencia';
            showToast('warn', `No puedes asignar una tarea en un día de ${tipoDia}.`);
            return;
        }
        // El token hay que cogerlo de las cookies
        const datos = {
            token: cookies.get('token'),
            id: props.match.params.id,
            trabajador: trabajadorAsociado,
            equipo: equipoAsociado,
            fecha: fechaOt,
            hora: hora,
            tipo: 1,
            plantilla: datosPlantilla,
            plantilla_seleccionada: plantillaSeleccionada,
            trabajos: trabajos,
            cliente: data.id_cliente,
            comentario_administracion: comentarioPreActuacion,
            direccion: datosOt.direccion,
            cp: datosOt.CP,
            provincia: datosOt.provincia,
            finalizado: 1,
            mensaje: mensaje,
        };

        axios.post('/updateOt', datos).then((data) => {
            if(!data.error) {
                showToast('ok', 'Datos actualizados correctamente.');
            } else {
                showToast('err', 'Error al actualizar los datos.');
            }
        }).catch((err) => {
            showToast('err', 'Error al actualizar los datos.');
        })
    }
    const descargarArchivos = (e) => {
        e.preventDefault();
        showToast('', 'Preparando los archivos para la descarga...');
        axios.get(`/descargarArchivos?token=${cookies.get('token')}&id=${props.match.params.id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((data) => {
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');
            link.href = url;
            link.className = 'descarga_archivo';
            link.setAttribute('download', 'archivo.zip');
            document.body.appendChild(link);
            link.click();
            document.getElementsByClassName('descarga_archivo')[0].remove();
        });
    }
    const getDatosPlantilla = (id_plantilla) => {
        const source = axios.CancelToken.source();
        axios.get(`/getDatosPlantilla?token=${cookies.get('token')}&id=${id_plantilla}&cliente=${data.id_cliente}`, { cancelToken: source.token, }).then(({ data }) => {
            setDatosPlantilla(data);
        }).catch((err) => {
            console.log(err);
        });
    }
    const handleOnChangePlantilla = (e) => {
        setPlantillaPrevia('');
        setPlantillaSeleccionada(e.target.value);
        getDatosPlantilla(e.target.value);
        console.log(e.target.value)
    }
    const addTarea = (e) => {
        e.preventDefault();
        axios.get(`/getItemsPreciario?token=${cookies.get('token')}&id=${data.id_cliente}`).then(({ data }) => {
            setHasItemsPreciario(data);
            setModalPreciario(true);
        })
    }
    const closeModalPreciario = (e) => {
        e.preventDefault();
        setModalPreciario(false);
    }
    const busquedaItem = (e) => {
        setTareaBusqueda(e.target.value);
        e.target.value.length ? setDesplegableTareas(true) : setDesplegableTareas(false);
        axios.get(`/busquedaItem?token=${cookies.get('token')}&id=${data.id_cliente}&busqueda=${e.target.value}`).then(({ data }) => {
            setItemsPreciario(data);
        });
    }
    const selectTarea = (e, descripcion, codigo, precio, unidad) => {
        setTrabajos([
            ...trabajos,
            { 
                id: e.target.id,
                descripcion,
                codigo,
                precio,
                unidad,
                cantidad: 1,
            },
        ]);
        setTareaBusqueda('');
        setDesplegableTareas(false);
    }
    const selectItem = (e, codigo, precio, unidad) => {
        console.log(e.target.id, e.target.name);
        if(!e.target.checked) {
            let arr = [...trabajos];
            let index = arr.findIndex(i => i.id === e.target.id.toString());
            arr.splice(index, 1);
            setTrabajos(arr);
            return;
        }
        setTrabajos([
            ...trabajos,
            { 
                id: e.target.id,
                descripcion: e.target.name,
                codigo,
                precio,
                unidad,
                cantidad: 1,
            },
        ]);
    }
    const getItem = (id) => {
        const index = trabajos.findIndex(i => i.id === id.toString());
        return index === -1 ? false : true;
    }
    const guardarTareas = (e) => {
        e.preventDefault();
        closeModalPreciario(e);
    }
    const selectHora = (e, hora_data) => {
        if(!hora.entrada) {
            setHora({
                ...hora,
                entrada: hora_data,
                id_entrada: e.target.id,
            });
        } else {
            if(parseInt(hora_data.slice(0, 2)) < parseInt(hora.entrada.slice(0, 2))) {
                setHora({
                    salida: hora.entrada,
                    id_salida: hora.id_entrada,
                    entrada: hora_data,
                    id_entrada: e.target.id,
                });
            } else {
                setHora({
                    ...hora,
                    salida: hora_data,
                    id_salida: e.target.id,
                });
            }
        }
    }
    const resetHora = (e) => {
        e.preventDefault();
        setHora({
            entrada: '',
            salida: '',
        });
    }
    const getHoraActive = (e) => {
        return hora.id_entrada === e.toString() || hora.id_salida === e.toString() ? 'active' : '';
    }
    const changeComentario = (e) => {
        setComentarioPreActuacion(e.target.value);
    }
    const reduceCantidadPlantilla = (e, index) => {
        let arr = [...datosPlantilla];
        if(arr[index].cantidad !== 1) arr[index].cantidad--;
        setDatosPlantilla(arr);
    }
    const addCantidadPlantilla = (e, index) => {
        let arr = [...datosPlantilla];
        arr[index].cantidad++;
        setDatosPlantilla(arr);
    }
    const reduceCantidadTrabajo = (e, index) => {
        let arr = [...trabajos];
        arr[index].cantidad--;
        setTrabajos(arr);
    }
    const addCantidadTrabajo = (e, index) => {
        let arr = [...trabajos];
        arr[index].cantidad++;
        setTrabajos(arr);
    }
    const closeModalAddFotos = (e) => {
        setModalAddFotos(false);
    }
    const saveFotos = (e) => {
        e.preventDefault()
        var formData = new FormData();
        const arrCopy = [...selectedPhotos.files];
        for (const key of Object.keys(arrCopy)) {
            formData.append('selectedPhotos', arrCopy[key], `${cookies.get('token')} ${data.id}-${arrCopy[key].name}`);
        }
        axios.post('/uploadFotos', formData, {
        }).then(res => {
            console.log(res.data)
        })

        closeModalAddFotos(e);
    }
    const addPhotos = (e) => {
        let formData = new FormData();
        formData.append('selectedPhotos', e.target.files[0], `${cookies.get('token')} ${data.id}-${e.target.id}.jpg`);
        setSelectedPhotos({ files: e.target.files });
        axios.post('/uploadFotos', formData).then(res => {
            console.log(res)
        })
    }
    const startOt = (e) => {
        navigator.geolocation.getCurrentPosition((position) => {
            console.log(position)
            const { latitude, longitude } = position.coords;
            
        }, (err) => {
            if(err.code === 1) {
                showToast('err', 'Tienes que permitir la geolocalización para poder iniciar la OT.');
                return;    
            }
            showToast('err', 'Error general, contacte con el administrador.')
        });
    }
    return (
        <>
            <h4>D10NET</h4>
            <Row>
                <Col md='10'>
                    <p>
                        D10NET COMUNICACIONES | {data.cifEmpresa} {data.direccionEmpresa}, {data.provinciaEmpresa}
                    </p>
                </Col>
                <Col>
                    <Button theme='success' outline onClick={startOt}>INICIAR</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput
                        id='fecha'
                        name='fecha'
                        placeholder='Fecha'
                        value={data.fecha}
                        disabled
                    />
                </Col>
                <Col>
                    <FormInput
                        id='cliente'
                        name='cliente'
                        placeholder='Cliente'
                        value={data.nombre_cliente}
                        disabled
                    />
                </Col>
                <Col>
                    <FormSelect value={data.id_cliente} disabled>
                        {
                            data.nombre_cliente === 'Elecnor' ?
                            <>
                                <option value='Ninguno'>Seleccionar departamento</option>
                                {    
                                    departamentos.map(({ id, nombre }, index) => (
                                        <option key={index} id={id} value={id}>{nombre}</option>
                                    ))
                                }
                            </>
                                :
                            <span></span>
                        }
                    </FormSelect>
                </Col>
                <Col>
                    <FormSelect>
                        <option value='Ninguna'>Seleccionar cuenta cargo</option>
                    </FormSelect>
                </Col>
            </Row>
            <br />
            <Row>
                
            </Row>
            <h6>Datos de la actuación</h6>
            <Row>
                <Col>
                    <FormInput
                        id='direccion'
                        name='direccion'
                        placeholder='Dirección'
                        onChange={handleOnChange}
                    />
                </Col>
                <Col>
                    <FormInput
                        id='CP'
                        name='CP'
                        placeholder='Código Postal'
                        onChange={handleOnChange}
                    />
                </Col>
                <Col>
                    <FormInput
                        id='provincia'
                        name='provincia'
                        placeholder='Provincia'
                        onChange={handleOnChange}
                    />
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <p style={{textAlign: 'justify'}}><b>Trabajos a realizar:<br/></b> 
                        <TrabajosRealizar>
                        {
                            <table className="table mb-0" style={{ fontSize: '.85em'}}>
                            <thead className="bg-light">
                                <tr>
                                    <th scope="col" className="border-0">
                                        Código
                                    </th>
                                    <th scope="col" className="border-0">
                                        Descripción
                                    </th>
                                    <th scope="col" className="border-0">
                                        Cantidad
                                    </th>
                                    <th scope="col" className="border-0">
                                        Precio unitario
                                    </th>
                                    <th scope="col" className="border-0">
                                        Precio total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {datosPlantilla.length && datosPlantilla.map(({ id, codigo, descripcion, cantidad, unidad, precio }, index) => (
                                <tr key={index} id={id}>
                                    <th>{codigo}</th>
                                    <th>{descripcion}</th>
                                    <th>
                                        <SpanCantidad onClick={(e) => reduceCantidadPlantilla(e, index)}>-</SpanCantidad>
                                            &nbsp;{cantidad}&nbsp;
                                        <SpanCantidad onClick={(e) => addCantidadPlantilla(e, index)}>+</SpanCantidad>
                                    </th>
                                    <th>{precio}</th>
                                    <th>{precio*cantidad}</th>
                                </tr>
                            ))}
                            {trabajos.length && trabajos.map(({ id, codigo, descripcion, cantidad, unidad, precio }, index) => (
                                <tr key={index} id={id}>
                                    <th>{codigo}</th>
                                    <th>{descripcion}</th>
                                    <th>
                                        <SpanCantidad onClick={(e) => reduceCantidadTrabajo(e, index)}>-</SpanCantidad>
                                            &nbsp;{cantidad}&nbsp;
                                        <SpanCantidad onClick={(e) => addCantidadTrabajo(e, index)}>+</SpanCantidad>
                                    </th>
                                    <th>{precio}</th>
                                    <th>{precio*cantidad}</th>
                                </tr>
                            ))}
                            </tbody>
                            </table>
                        }
                        </TrabajosRealizar>
                        <br />
                        <Row>
                            <Col md='8'>
                                <FormInput placeholder='Añadir tarea...' value={tareaBusqueda} onChange={busquedaItem} />
                                {
                                desplegableTareas &&
                                <ShowTareas>
                                    {
                                        itemsPreciario.map(({ id, descripcion, codigo, precio, unidad }, index) => (
                                            <TareaData key={index} id={id} onClick={(e) => selectTarea(e, descripcion, codigo, precio, unidad)}>{descripcion}</TareaData>
                                        ))
                                    }
                                </ShowTareas>
                                }
                            </Col>
                            <Col>
                                <FormSelect value={plantillaSeleccionada} onChange={handleOnChangePlantilla}>
                                    <option value='Ninguno'>Seleccionar plantilla</option>
                                    {
                                        plantillas.map(({id, nombre}, index) => (
                                            <option key={index} id={id} value={id}>{nombre}</option>
                                        ))
                                    }
                                </FormSelect>
                            </Col>
                        </Row>
                    </p>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <p style={{textAlign: 'justify'}}><b>Datos de actuación:<br/></b> <MsgOt onChange={(e) => setMensaje(e.target.value)} value={mensaje} /></p>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <FormTextarea rows='5' placeholder='Comentario pre actuación' onChange={changeComentario} value={comentarioPreActuacion} />
                </Col>
            </Row>
            <br />
            {comentarioTecnico.map((item, index) => (
                <>
                <Row key={index}>
                    <Col>
                    <FormTextarea
                        id={item.id}
                        rows="5"
                        placeholder="Comentario técnico"
                        defaultValue={item.comentario}
                        disabled
                    />
                    </Col>
                </Row>
                <br />
                </>
            ))}
            <Row className='mt-2'>
            <Col>
                    <p>
                        <FormSelect size='sm' value={trabajadorAsociado} onChange={changeTrabajadoAsociado}>
                            <option value='Ninguno'>Seleccionar trabajador</option>
                            {
                                trabajadores.map(({id, nombre}, index) => (
                                    <option key={index} id={id} value={id}>{nombre}</option>
                                ))
                            }
                        </FormSelect>
                    </p>
                    <p>
                        <FormSelect size='sm' value={equipoAsociado} onChange={changeEquipoAsociado}>
                            <option value='Ninguno'>Seleccionar equipo</option>
                            {
                                equipos.map(({id, nombre}, index) => (
                                    <option key={index} id={id} value={id}>{nombre}</option>
                                ))
                            }
                        </FormSelect>
                    </p>
                    <Hora onClick={(e ) => selectHora(e, '07:00')} id='35' active={getHoraActive(35)}>07:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '07:30')} id='36' active={getHoraActive(36)}>07:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '08:00')} id='1' active={getHoraActive(1)}>08:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '08:30')} id='2' active={getHoraActive(2)}>08:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '09:00')} id='3' active={getHoraActive(3)}>09:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '09:30')} id='4' active={getHoraActive(4)}>09:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '10:00')} id='5' active={getHoraActive(5)}>10:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '10:30')} id='6' active={getHoraActive(6)}>10:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '11:00')} id='7' active={getHoraActive(7)}>11:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '11:30')} id='8' active={getHoraActive(8)}>11:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '12:00')} id='9' active={getHoraActive(9)}>12:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '12:30')} id='10' active={getHoraActive(10)}>12:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '13:00')} id='11' active={getHoraActive(11)}>13:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '13:30')} id='12' active={getHoraActive(12)}>13:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '14:00')} id='13' active={getHoraActive(13)}>14:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '14:30')} id='14' active={getHoraActive(14)}>14:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '15:00')} id='15' active={getHoraActive(15)}>15:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '15:30')} id='16' active={getHoraActive(16)}>15:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '16:00')} id='17' active={getHoraActive(17)}>16:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '16:30')} id='18' active={getHoraActive(18)}>16:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '17:00')} id='19' active={getHoraActive(19)}>17:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '17:30')} id='20' active={getHoraActive(20)}>17:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '18:00')} id='21' active={getHoraActive(21)}>18:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '18:30')} id='22' active={getHoraActive(22)}>18:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '19:00')} id='23' active={getHoraActive(23)}>19:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '19:30')} id='24' active={getHoraActive(24)}>19:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '20:00')} id='25' active={getHoraActive(25)}>20:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '20:30')} id='26' active={getHoraActive(26)}>20:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '21:00')} id='27' active={getHoraActive(27)}>21:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '21:30')} id='28' active={getHoraActive(28)}>21:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '22:00')} id='29' active={getHoraActive(29)}>22:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '22:30')} id='30' active={getHoraActive(30)}>22:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '23:00')} id='31' active={getHoraActive(31)}>23:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '23:30')} id='32' active={getHoraActive(32)}>23:30</Hora>
                    <Hora onClick={(e ) => selectHora(e, '00:00')} id='33' active={getHoraActive(33)}>00:00</Hora>
                    <Hora onClick={(e ) => selectHora(e, '00:30')} id='34' active={getHoraActive(34)}>00:30</Hora>
                    <br />
                    <ResetHora onClick={resetHora}>Resetear selección</ResetHora>
                </Col>
                <Col>
                    <Calendar tileClassName={checkDays} onClickDay={clickDay} />
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Row>
                        <InfoDia data={diaSeleccionado} />
                    </Row>
                </Col>
            </Row>
            <br />
            <Row>
                <Col md='8'></Col>
                <Col>
                    <Button outline theme='primary' onClick={() => setModalAddFotos(true)}>Subir fotos</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button outline theme='dark'>Cerrar parte</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button outline theme='info' onClick={descargarArchivos}>Descargar archivos</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button theme='success' outline onClick={saveOt}>Guardar</Button>
                </Col>
            </Row>
            <Modal open={modalAddFotos} toggle={closeModalAddFotos} size='lg'>
                <ModalHeader>Añadir fotos</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <p>CR - FIST/DVCAN</p>
                            <LabelFile htmlFor='frontal'><FileIcon src={Upload} />Frontal<InputFile id='frontal' type='file' onChange={addPhotos} /></LabelFile>
                            <LabelFile htmlFor='abierta'><FileIcon src={Upload} />Abierta<InputFile id='abierta' type='file' onChange={addPhotos} /></LabelFile>
                            <LabelFile htmlFor='panoramica'><FileIcon src={Upload} />Panorámica<InputFile id='panoramica' type='file' onChange={addPhotos} /></LabelFile>
                            <p>Nombre arqueta</p>
                            <LabelFile htmlFor='abierta_arqueta'><FileIcon src={Upload} />Abierta<InputFile id='abierta_arqueta' type='file' onChange={addPhotos} /></LabelFile>
                            <LabelFile htmlFor='entrada_cable'><FileIcon src={Upload} />Entradas cable<InputFile id='entrada_cable' type='file' onChange={addPhotos} /></LabelFile>
                            <LabelFile htmlFor='salidas_cable'><FileIcon src={Upload} />Salidas cable<InputFile id='salidas_cable' type='file' onChange={addPhotos} /></LabelFile>
                            <p>Varios</p>
                            {
                                totalInputs.map((input, index) => (
                                    <LabelFile htmlFor={`varios_${index}`}><FileIcon src={Upload} />Archivo {index}<InputFile id={`varios_${index}`} type='file' onChange={addPhotos} /></LabelFile>
                                ))
                            }
                            <br /><br />
                            <Button outline theme='info' onClick={() => setTotalInputs([...totalInputs, 0])}>Nuevo archivo</Button>

                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button outline theme='danger' onClick={closeModalAddFotos}>Cancelar</Button>
                    <Button outline onClick={saveFotos}>Guardar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const MsgOt = styled.textarea`
    padding: 5px;
    border: 3px solid rgba(0, 0, 0, 1);
    border-radius: 15px;
    text-align: justify;
    width: 100%;
    height: 30vh;
    overflow: auto;
    ::-webkit-scrollbar {
        background: transparent;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .1) !important;
      border-radius: 200px;
    }
    ::-webkit-scrollbar-corner {
        background: none;
    }
`;
const TrabajosRealizar = styled.div`
    padding: 5px;
    text-align: justify;
    max-height: 30vh;
    overflow: auto;
    margin-bottom: 5px;
    ::-webkit-scrollbar {
        background: transparent;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .1) !important;
      border-radius: 200px;
    }
    ::-webkit-scrollbar-corner {
        background: none;
    }
`;

const Hora = styled.div`
    display: inline-block;
    height: 3.5vh;
    width: 3.5vw;
    border: 1px solid black;
    border-radius: 5px;
    margin: .1em;
    text-align: center;
    cursor: pointer;
    transition: all .1s ease-in-out;
    &:hover {
        background-color: orange;
    }
    ${({ active }) => active === 'active' &&`
        background: orange;
    `}
`;

const ResetHora = styled.span`
    cursor: pointer;
    color: blue;
    &:hover {
        text-decoration: underline;
    }
`;

const SpanCantidad = styled.span`
    cursor: pointer;
    display: inline-block;
    border: 1px solid black;
    border-color: transparent;
    border-radius: 20px;
    width: 1vw;
    text-align: center;
    background-color: rgba(102, 103, 104, 0.2);
`;

const ShowTareas = styled.div`
    background-color: white;
    max-height: 15vh;
    overflow: auto;
`;

const TareaData = styled.span`
    cursor: pointer;
    font-size: .8em;
    display: block;
    border-bottom: 1px solid #eee;
    transition: all .1s linear;
    &:hover {
        background-color: #eee;
    }
`;

const InputFile = styled.input`
    width: 0,1px;
    height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;

const LabelFile = styled.label`
    font-size: 1.05em;
    font-weight: 700;
    color: white;
    background-color: rgba(100, 149, 237, 0.5);
    display: inline-block;
    padding: 1em;
    border-radius: 5px;
    border: 3px solid cornflowerblue;
    color: #417DC1;
    margin-right: 5px;
    width: 13em;
    text-align: center;
`;

const FileIcon = styled.img`
    width: 2em;
    height: 2em;
    display: inline-block;
    margin-right: 5px;
`;

export default OTSeleccionada;
