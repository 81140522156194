// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
    return [
      {
          title: "Panel de control",
          to: "/blog-overview",
          htmlBefore: '<i class="material-icons">edit</i>',
      },
      {
          title: "Sedes",
          to: "/sedes",
          htmlBefore: '<i class="material-icons">edit</i>',
      },
      {
          title: "Vehículos",
          to: "/vehiculos",
          htmlBefore: '<i class="material-icons">edit</i>',
      },
      {
          title: "Nuevo vehículo",
          to: "/nuevo-vehiculo",
          htmlBefore: '<i class="material-icons">edit</i>',
      },
    ];
  }
  